import _isEqual from 'lodash/isEqual'

export const NAMESPACE = 'profile'

export const CV_PARTS = {
  PERSONAL_DATA: 'personalData',
  WORK_EXPERIENCE: 'workExperiences',
  CERTIFICATE: 'certificates',
  EDUCATION: 'educations',
  COMPETENCE: 'competences',
  LANGUAGE: 'languages'
}

export const CV_PARTS_CONFIG = {
  [CV_PARTS.PERSONAL_DATA]: {
    property: CV_PARTS.PERSONAL_DATA, // must match property name
    formName: 'personal_data',
  },
  [CV_PARTS.COMPETENCE]: {
    property: CV_PARTS.COMPETENCE,
    formName: 'competence_array',
    formDataWrapper: 'competences',
  },
  [CV_PARTS.WORK_EXPERIENCE]: {
    property: CV_PARTS.WORK_EXPERIENCE,
    formName: 'work_experience_array',
    formDataWrapper: 'experiences',
  },
  [CV_PARTS.CERTIFICATE]: {
    property: CV_PARTS.CERTIFICATE,
    formName: 'certificate_array',
    formDataWrapper: 'certificates',
  },
  [CV_PARTS.EDUCATION]: {
    property: CV_PARTS.EDUCATION,
    formName: 'education_array',
    formDataWrapper: 'educations',
  },
  [CV_PARTS.LANGUAGE]: {
    property: CV_PARTS.LANGUAGE,
    formName: 'language_array',
    formDataWrapper: 'languages',
  }
}

export const ACTIONS = {
  LOAD_CV: 'loadCV',
  SAVE_CV_PART: 'saveCVPart',
  LOAD_PROFILE: 'loadProfile',
}

export const GETTERS = {
  IS_PART_UPDATED: 'isPartUpdated',
}


export const MUTATIONS = {
  SET_CV: 'SET_CV',
  SET_CV_PART: 'SET_CV_PART', // set is used to update only current not saved model
  UPDATE_CV_PART: 'UPDATE_CV_PART', // update is used when response is returned after part is saved and its updating original model
  RESET_CV_PART: 'RESET_CV_PART',
  SET_LOADING: 'SET_LOADING',
  SET_SAVING: 'SET_SAVING',
  SET_PROFILE: 'SET_PROFILE',
}

export const mutations = {
  [MUTATIONS.SET_LOADING](state, isLoading) {
    state[STATE.IS_LOADING] = isLoading
  },
  [MUTATIONS.SET_SAVING](state, isSaving) {
    state[STATE.IS_SAVING] = isSaving
  },
  [MUTATIONS.SET_CV](state, cv) {
    state[STATE.CV] = cv
    state[STATE.CV_ORIGINAL] = JSON.parse(JSON.stringify(cv))
  },
  [MUTATIONS.UPDATE_CV_PART](state, { cv, part }) {
    state[STATE.CV][part] = cv[part]
    state[STATE.CV_ORIGINAL][part] = JSON.parse(JSON.stringify(cv))[part]
  },
  [MUTATIONS.SET_CV_PART](state, { part, content }) {
    state[STATE.CV][part] = content
  },
  [MUTATIONS.RESET_CV_PART](state, part) {
    state[STATE.CV][part] = JSON.parse(JSON.stringify(state[STATE.CV_ORIGINAL][part]))
  },
  _resetState(state) {
    Object.assign(state, getDefaultState())
  }
}

export const getters = {
  [GETTERS.IS_PART_UPDATED]: (state) => (part) => {
    return !_isEqual(state[STATE.CV][part.property], state[STATE.CV_ORIGINAL][part.property])
  }
}

export const actions = {
  async [ACTIONS.LOAD_CV]({ commit }) {
    try {
      commit(MUTATIONS.SET_LOADING, true)
      const response = await this.$axios.$get('/jh-api/me/cv')
      commit(MUTATIONS.SET_CV, response)
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.SET_LOADING, false)
    }
  },
  async [ACTIONS.LOAD_PROFILE]({ commit }) {
    try {
      const response = await this.$axios.$get('/jh-api/me/profile')
      commit(MUTATIONS.SET_PROFILE, response)
    } catch (e) {
      throw e
    }
  },
  async [ACTIONS.SAVE_CV_PART]({ commit, state }, part) {
    try {
      commit(MUTATIONS.SET_SAVING, true)

      let model = null
      if (part.hasOwnProperty('formDataWrapper')) {
        model = { [part.formDataWrapper]: state[STATE.CV][part.property] }
      } else {
        model = state[STATE.CV][part.property]
      }

      const response = await this.$axios.$put('/jh-api/me/cv', {
        [part.formName]: model
      })
      commit(MUTATIONS.UPDATE_CV_PART, { cv: response, part: part.property })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.SET_SAVING, false)
    }
  },
}

export const STATE = {
  CV: 'CV',
  CV_ORIGINAL: 'CV_ORIGINAL',
  PROFILE: 'PROFILE',
  IS_LOADING: 'IS_LOADING',
  IS_SAVING: 'IS_SAVING'
}

const getDefaultState = () => ({
  [STATE.CV]: {}, // updated state by user not synchronized with server
  [STATE.CV_ORIGINAL]: {}, // state from server
  [STATE.PROFILE]: {},
  [STATE.IS_LOADING]: false,
  [STATE.IS_SAVING]: false
})

export const state = getDefaultState
