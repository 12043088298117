<template>
  <div>
    <template v-if="$auth.hasRole('ROLE_ADMIN_ACCESS')">
      <v-btn
        v-if="$auth.hasRole('ROLE_ADMIN_ACCESS')"
        class="hidden-sm-and-down"
        to="/admin"
        flat
      >
        Administrace
      </v-btn>
    </template>
    <no-ssr>
      <template v-if="$auth.isLoggedIn && $auth.user">
        <v-menu
          :close-on-content-click="false"
          :value="isUserMenuOpen"
          offset-y
          bottom
          left
          @input="onUserMenuChange"
        >
          <v-btn
            slot="activator"
            class="mr-0"
            flat
          >
            <div class="v-avatar mr-1" style="width: 24px;height:24px;">
              <avatar :user="$auth.user" />
            </div>
            <div class="hidden-xs-only">
              {{ $auth.user.fullName }}
            </div>
          </v-btn>
          <v-card>
            <v-list>
              <v-list-tile
                avatar
                to="/profil"
              >
                <v-list-tile-avatar>
                  <avatar :user="$auth.user" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ $auth.user.fullName }}</v-list-tile-title>
                  <v-list-tile-sub-title>
                    {{ $auth.user.email }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                to="/profil/zivotopis"
              >
                <v-list-tile-content>
                  <v-list-tile-title>Můj životopis</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                to="/profil/nastaveni"
              >
                <v-list-tile-content>
                  <v-list-tile-title>Nastavení</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                flat
                @click="$auth.logout()"
              >
                Odhlásit se
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
      <template v-else>
        <v-btn
          flat
          title="Přihlášení"
          icon
          @click="openLoginForm"
        >
          <v-icon>flaticon-user</v-icon>
        </v-btn>
      </template>
    </no-ssr>
  </div>
</template>

<script>
import Avatar from '~/components/User/Avatar'
import { MUTATIONS, STATE } from '~/store/index'

export default {
  components: {Avatar},
  data() {
    return {
      userMenu: false,
      adminMenu: false
    }
  },
  computed: {
    isUserMenuOpen() {
      return this.$store.state[STATE.isUserMenuOpen]
    }
  },
  methods: {
    onUserMenuChange(e) {
      this.$store.commit(MUTATIONS.SET_USER_MENU, e)
    },
    openLoginForm() {
      this.$store.commit(MUTATIONS.SET_LOGIN_MODAL, true)
    }
  }
}
</script>
