import Vue from 'vue'

const colors = ['success', 'info', 'error']

const DEFAULT_OPTIONS = {
  text: '',
  icon: '',
  color: 'info',
  timeout: 3000,
  dismissible: true
}

const ToastCmp = {
  render(h) {
    return h(
      'v-snackbar',
      {
        staticClass: 'application',
        attrs: {
          timeout: this.timeout,
          color: this.color,
          value: this.active
        },
        on: {
          click: this.dismiss
        }
      },
      [
        this.icon.length > 0
          ? h('v-icon', { attrs: { dark: true, left: true } }, [this.icon])
          : null,
        this.text
      ]
    )
  },
  data() {
    return {
      active: false,
      text: '',
      icon: '',
      color: 'info',
      timeout: 3000,
      dismissible: true
    }
  },

  methods: {
    show(options = {}) {
      if (this.active) {
        this.close()
        this.$nextTick(() => this.show(options))
        return
      }

      Object.keys(options).forEach(field => (this[field] = options[field]))

      this.active = true
    },

    close() {
      this.active = false
    },

    dismiss() {
      if (this.dismissible) {
        this.active = false
      }
    }
  }
}

export default class Toast {
  constructor() {
    colors.forEach(
      color =>
        (this[color] = (text, options = {}) =>
          this.show({ color, text, ...options }))
    )
    this.component = undefined
  }

  getComponent() {
    if (!this.component) {
      const cmp = new Vue(ToastCmp)
      window.document.body.appendChild(cmp.$mount().$el)
      this.component = cmp
    }
    return this.component
  }

  show(options) {
    if (!process.browser) {
      return
    }
    this.getComponent().show({ ...DEFAULT_OPTIONS, ...options })
  }

  close() {
    if (!process.browser) {
      return
    }
    this.getComponent().close()
  }
}
