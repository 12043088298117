export default function(req, res, next) {
  if (res) {
    res.setHeader('X-Frame-options', 'deny')
    res.setHeader('access-control-allow-credentials', 'true')
    res.setHeader(
      'Content-Security-Policy',
      'default-src \'self\'; block-all-mixed-content; connect-src \'self\'; font-src https://fonts.gstatic.com \'self\'; img-src \'self\'; script-src https://cdnjs.cloudflare.com \'self\' \'unsafe-inline\' \'unsafe-eval\'; style-src https://fonts.googleapis.com \'self\' \'unsafe-inline\''
    )
    res.setHeader(
      'X-Content-Security-Policy',
      'default-src \'none\'; block-all-mixed-content; connect-src *.nsp.cz \'self\'; font-src *.nsp.cz https://fonts.gstatic.com \'self\'; img-src *.nsp.cz \'self\'; script-src *.job-hub.cz https://cdnjs.cloudflare.com \'self\' \'unsafe-inline\' \'unsafe-eval\'; style-src *.job-hub.cz https://fonts.googleapis.com \'self\' \'unsafe-inline\''
    )
    res.setHeader('X-Content-Type-Options', 'nosniff')
    res.setHeader('X-Xss-Protection', '1; mode=block')
    res.setHeader(
      'Referrer-Policy',
      'no-referrer, strict-origin-when-cross-origin'
    )
    res.setHeader(
      'Permissions-Policy',
      'accelerometer=(), ambient-light-sensor=(), autoplay=(), battery=(), camera=(), cross-origin-isolated=(), display-capture=(), document-domain=(), encrypted-media=(), execution-while-not-rendered=(), execution-while-out-of-viewport=(), fullscreen=(), geolocation=(), gyroscope=(), keyboard-map=(), magnetometer=(), microphone=(), midi=(), navigation-override=(), payment=(), picture-in-picture=(), publickey-credentials-get=(), screen-wake-lock=(), sync-xhr=(), usb=(), web-share=(), xr-spatial-tracking=(), clipboard-read=(), clipboard-write=(), gamepad=(), speaker-selection=(), conversion-measurement=(), focus-without-user-activation=(), hid=(), idle-detection=(), interest-cohort=(), serial=(), sync-script=(), trust-token-redemption=(), unload=(), window-placement=(), vertical-scroll=()'
    )
  }
  next()
}
