<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline" primary-title>
        Zadejte Váš email pro odeslání testu
      </v-card-title>
      <template>
        <v-card-text>
          <ul class="mb-3">
            <li>Na váš email odešleme odkaz pro přístup k testu.</li>
            <li>Týden před automatickým smazáním testu Vás upozorníme.</li>
          </ul>
          <v-form ref="form" v-model="isValid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email"
              required
              validate-on-blur
              outline
            />
            <v-flex text-xs-right>
              <v-btn
                :loading="isLoading"
                color="jhM1"
                class="jh-white--text"
                @click="submit"
              >
                Odeslat
              </v-btn>
            </v-flex>
          </v-form>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      url: String,
      code: String,
      context: String,
    },
    data() {
      return {
        isValid: false,
        isLoading: false,
        email: '',
        emailRules: [
          v => !!v || 'Email je povinný',
          v =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'Neplatný email'
        ],
        isDialogOpen: false,
      }
    },
    methods: {
      async submit() {
        if (!this.isValid || !this.$refs.form.validate()) {
          return
        }
        try {
          this.isLoading = true
          await this.$axios.$post(this.url, {
            'test_email': { email: this.email }
          },{
            params: {
              code: this.code,
              context: this.context
            }
          })
          this.$toast.success('Na Vámi zadaný email jsme poslali upozornění.')
          this.$emit('success')
          this.isDialogOpen = false
        } catch (e) {
          // just finally
        } finally {
          this.isLoading = false
        }
      },
      close() {
        this.isDialogOpen = false
      },
      open() {
        this.isDialogOpen = true
      },
    }
  }
</script>
