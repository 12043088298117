import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5309fadc = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _67b55574 = () => interopDefault(import('../pages/dokumenty.vue' /* webpackChunkName: "pages/dokumenty" */))
const _1650427a = () => interopDefault(import('../pages/informace-o-trhu-prace/index.vue' /* webpackChunkName: "pages/informace-o-trhu-prace/index" */))
const _7b10efbe = () => interopDefault(import('../pages/informace-pro-up/index.vue' /* webpackChunkName: "pages/informace-pro-up/index" */))
const _641261ff = () => interopDefault(import('../pages/katalog-poradcu/index.vue' /* webpackChunkName: "pages/katalog-poradcu/index" */))
const _e891e582 = () => interopDefault(import('../pages/podstranka.vue' /* webpackChunkName: "pages/podstranka" */))
const _33c88f22 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _165bffbe = () => interopDefault(import('../pages/pruvodce-zivotnimi-situacemi/index.vue' /* webpackChunkName: "pages/pruvodce-zivotnimi-situacemi/index" */))
const _28dae3ba = () => interopDefault(import('../pages/registrace/index.vue' /* webpackChunkName: "pages/registrace/index" */))
const _0edc07dc = () => interopDefault(import('../pages/sebeevaluace/index.vue' /* webpackChunkName: "pages/sebeevaluace/index" */))
const _604c2635 = () => interopDefault(import('../pages/zapomenute-heslo/index.vue' /* webpackChunkName: "pages/zapomenute-heslo/index" */))
const _29cc4da8 = () => interopDefault(import('../pages/admin/CMS/index.vue' /* webpackChunkName: "pages/admin/CMS/index" */))
const _3f80b900 = () => interopDefault(import('../pages/admin/company/index.vue' /* webpackChunkName: "pages/admin/company/index" */))
const _856e557e = () => interopDefault(import('../pages/admin/profese/index.vue' /* webpackChunkName: "pages/admin/profese/index" */))
const _2c019ab6 = () => interopDefault(import('../pages/admin/segment/index.vue' /* webpackChunkName: "pages/admin/segment/index" */))
const _638b3387 = () => interopDefault(import('../pages/admin/terms-agreement/index.vue' /* webpackChunkName: "pages/admin/terms-agreement/index" */))
const _1a5d0c3a = () => interopDefault(import('../pages/admin/UsefulLinks.vue' /* webpackChunkName: "pages/admin/UsefulLinks" */))
const _057c5ac2 = () => interopDefault(import('../pages/admin/uzivatel/index.vue' /* webpackChunkName: "pages/admin/uzivatel/index" */))
const _1bdd31ee = () => interopDefault(import('../pages/informace-o-trhu-prace/volna-mista.vue' /* webpackChunkName: "pages/informace-o-trhu-prace/volna-mista" */))
const _4927e58a = () => interopDefault(import('../pages/informace-o-trhu-prace/zamestnavatele/index.vue' /* webpackChunkName: "pages/informace-o-trhu-prace/zamestnavatele/index" */))
const _1618b36e = () => interopDefault(import('../pages/informace-pro-up/odkazy/index.vue' /* webpackChunkName: "pages/informace-pro-up/odkazy/index" */))
const _a5425b72 = () => interopDefault(import('../pages/informace-pro-up/profesiogramy/index.vue' /* webpackChunkName: "pages/informace-pro-up/profesiogramy/index" */))
const _9fb66a86 = () => interopDefault(import('../pages/katalog-poradcu/vytvoreni-nove-metody.vue' /* webpackChunkName: "pages/katalog-poradcu/vytvoreni-nove-metody" */))
const _dad1fa86 = () => interopDefault(import('../pages/l/mbti.vue' /* webpackChunkName: "pages/l/mbti" */))
const _db5878a0 = () => interopDefault(import('../pages/l/obnova-hesla.vue' /* webpackChunkName: "pages/l/obnova-hesla" */))
const _033bc87a = () => interopDefault(import('../pages/l/registrace.vue' /* webpackChunkName: "pages/l/registrace" */))
const _cd46e3dc = () => interopDefault(import('../pages/l/sebeevaluace.vue' /* webpackChunkName: "pages/l/sebeevaluace" */))
const _06ad9bd4 = () => interopDefault(import('../pages/l/zmena-emailu.vue' /* webpackChunkName: "pages/l/zmena-emailu" */))
const _2c1b9854 = () => interopDefault(import('../pages/profil/nastaveni.vue' /* webpackChunkName: "pages/profil/nastaveni" */))
const _0e3d9a98 = () => interopDefault(import('../pages/profil/zivotopis.vue' /* webpackChunkName: "pages/profil/zivotopis" */))
const _2f21f6ae = () => interopDefault(import('../pages/registrace/uspesna-registrace.vue' /* webpackChunkName: "pages/registrace/uspesna-registrace" */))
const _645bdf5e = () => interopDefault(import('../pages/admin/CMS/files.vue' /* webpackChunkName: "pages/admin/CMS/files" */))
const _304811b6 = () => interopDefault(import('../pages/admin/service/command_log.vue' /* webpackChunkName: "pages/admin/service/command_log" */))
const _760edff2 = () => interopDefault(import('../pages/informace-pro-up/clanky/cv-a-motivacni-dopis.vue' /* webpackChunkName: "pages/informace-pro-up/clanky/cv-a-motivacni-dopis" */))
const _174bee1c = () => interopDefault(import('../pages/informace-pro-up/clanky/prijimaci-pohovor.vue' /* webpackChunkName: "pages/informace-pro-up/clanky/prijimaci-pohovor" */))
const _75c01473 = () => interopDefault(import('../pages/sebeevaluace/test/mbti-pro-dospele/index.vue' /* webpackChunkName: "pages/sebeevaluace/test/mbti-pro-dospele/index" */))
const _2d363220 = () => interopDefault(import('../pages/sebeevaluace/test/mbti-pro-mladez/index.vue' /* webpackChunkName: "pages/sebeevaluace/test/mbti-pro-mladez/index" */))
const _d0234c82 = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/index.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/index" */))
const _c032f1fe = () => interopDefault(import('../pages/sebeevaluace/test/mbti-pro-dospele/test.vue' /* webpackChunkName: "pages/sebeevaluace/test/mbti-pro-dospele/test" */))
const _38c51750 = () => interopDefault(import('../pages/sebeevaluace/test/mbti-pro-dospele/vysledek.vue' /* webpackChunkName: "pages/sebeevaluace/test/mbti-pro-dospele/vysledek" */))
const _594ef0e4 = () => interopDefault(import('../pages/sebeevaluace/test/mbti-pro-mladez/test.vue' /* webpackChunkName: "pages/sebeevaluace/test/mbti-pro-mladez/test" */))
const _5b60509a = () => interopDefault(import('../pages/sebeevaluace/test/mbti-pro-mladez/vysledek.vue' /* webpackChunkName: "pages/sebeevaluace/test/mbti-pro-mladez/vysledek" */))
const _11d3f825 = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/vyhodnoceni.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/vyhodnoceni" */))
const _df6a7096 = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/co-chci/plat.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/co-chci/plat" */))
const _3a906f6c = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/co-chci/pracovni-podminky.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/co-chci/pracovni-podminky" */))
const _5141040c = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/co-umim/aktivity.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/co-umim/aktivity" */))
const _d370434e = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/co-umim/jazyky.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/co-umim/jazyky" */))
const _dcf22114 = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/co-umim/kompetence.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/co-umim/kompetence" */))
const _557519aa = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/co-umim/vzdelani.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/co-umim/vzdelani" */))
const _89a31b0a = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/moje-prace/kvalifikace-narocnost.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/moje-prace/kvalifikace-narocnost" */))
const _de9e7fbe = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/moje-prace/obor.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/moje-prace/obor" */))
const _722f8da1 = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/moje-prace/role.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/moje-prace/role" */))
const _5204d35b = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/moje-prace/zakonem-pozadovana-praxe.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/moje-prace/zakonem-pozadovana-praxe" */))
const _13127d3f = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/o-me/osobnost.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/o-me/osobnost" */))
const _0faaf8f2 = () => interopDefault(import('../pages/sebeevaluace/test/sebeevaluace/o-me/zdravotni-omezeni.vue' /* webpackChunkName: "pages/sebeevaluace/test/sebeevaluace/o-me/zdravotni-omezeni" */))
const _3c7fdc60 = () => interopDefault(import('../pages/admin/CMS/page/_id.vue' /* webpackChunkName: "pages/admin/CMS/page/_id" */))
const _733ef430 = () => interopDefault(import('../pages/admin/company/_id.vue' /* webpackChunkName: "pages/admin/company/_id" */))
const _5182d29e = () => interopDefault(import('../pages/admin/segment/_id.vue' /* webpackChunkName: "pages/admin/segment/_id" */))
const _8a635014 = () => interopDefault(import('../pages/informace-o-trhu-prace/segment/_urlSlug.vue' /* webpackChunkName: "pages/informace-o-trhu-prace/segment/_urlSlug" */))
const _43aed3f1 = () => interopDefault(import('../pages/informace-o-trhu-prace/zamestnavatele/_companyId.vue' /* webpackChunkName: "pages/informace-o-trhu-prace/zamestnavatele/_companyId" */))
const _64951f52 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _5309fadc,
    name: "admin"
  }, {
    path: "/dokumenty",
    component: _67b55574,
    name: "dokumenty"
  }, {
    path: "/informace-o-trhu-prace",
    component: _1650427a,
    name: "informace-o-trhu-prace"
  }, {
    path: "/informace-pro-up",
    component: _7b10efbe,
    name: "informace-pro-up"
  }, {
    path: "/katalog-poradcu",
    component: _641261ff,
    name: "katalog-poradcu"
  }, {
    path: "/podstranka",
    component: _e891e582,
    name: "podstranka"
  }, {
    path: "/profil",
    component: _33c88f22,
    name: "profil"
  }, {
    path: "/pruvodce-zivotnimi-situacemi",
    component: _165bffbe,
    name: "pruvodce-zivotnimi-situacemi"
  }, {
    path: "/registrace",
    component: _28dae3ba,
    name: "registrace"
  }, {
    path: "/sebeevaluace",
    component: _0edc07dc,
    name: "sebeevaluace"
  }, {
    path: "/zapomenute-heslo",
    component: _604c2635,
    name: "zapomenute-heslo"
  }, {
    path: "/admin/CMS",
    component: _29cc4da8,
    name: "admin-CMS"
  }, {
    path: "/admin/company",
    component: _3f80b900,
    name: "admin-company"
  }, {
    path: "/admin/profese",
    component: _856e557e,
    name: "admin-profese"
  }, {
    path: "/admin/segment",
    component: _2c019ab6,
    name: "admin-segment"
  }, {
    path: "/admin/terms-agreement",
    component: _638b3387,
    name: "admin-terms-agreement"
  }, {
    path: "/admin/UsefulLinks",
    component: _1a5d0c3a,
    name: "admin-UsefulLinks"
  }, {
    path: "/admin/uzivatel",
    component: _057c5ac2,
    name: "admin-uzivatel"
  }, {
    path: "/informace-o-trhu-prace/volna-mista",
    component: _1bdd31ee,
    name: "informace-o-trhu-prace-volna-mista"
  }, {
    path: "/informace-o-trhu-prace/zamestnavatele",
    component: _4927e58a,
    name: "informace-o-trhu-prace-zamestnavatele"
  }, {
    path: "/informace-pro-up/odkazy",
    component: _1618b36e,
    name: "informace-pro-up-odkazy"
  }, {
    path: "/informace-pro-up/profesiogramy",
    component: _a5425b72,
    name: "informace-pro-up-profesiogramy"
  }, {
    path: "/katalog-poradcu/vytvoreni-nove-metody",
    component: _9fb66a86,
    name: "katalog-poradcu-vytvoreni-nove-metody"
  }, {
    path: "/l/mbti",
    component: _dad1fa86,
    name: "l-mbti"
  }, {
    path: "/l/obnova-hesla",
    component: _db5878a0,
    name: "l-obnova-hesla"
  }, {
    path: "/l/registrace",
    component: _033bc87a,
    name: "l-registrace"
  }, {
    path: "/l/sebeevaluace",
    component: _cd46e3dc,
    name: "l-sebeevaluace"
  }, {
    path: "/l/zmena-emailu",
    component: _06ad9bd4,
    name: "l-zmena-emailu"
  }, {
    path: "/profil/nastaveni",
    component: _2c1b9854,
    name: "profil-nastaveni"
  }, {
    path: "/profil/zivotopis",
    component: _0e3d9a98,
    name: "profil-zivotopis"
  }, {
    path: "/registrace/uspesna-registrace",
    component: _2f21f6ae,
    name: "registrace-uspesna-registrace"
  }, {
    path: "/admin/CMS/files",
    component: _645bdf5e,
    name: "admin-CMS-files"
  }, {
    path: "/admin/service/command_log",
    component: _304811b6,
    name: "admin-service-command_log"
  }, {
    path: "/informace-pro-up/clanky/cv-a-motivacni-dopis",
    component: _760edff2,
    name: "informace-pro-up-clanky-cv-a-motivacni-dopis"
  }, {
    path: "/informace-pro-up/clanky/prijimaci-pohovor",
    component: _174bee1c,
    name: "informace-pro-up-clanky-prijimaci-pohovor"
  }, {
    path: "/sebeevaluace/test/mbti-pro-dospele",
    component: _75c01473,
    name: "sebeevaluace-test-mbti-pro-dospele"
  }, {
    path: "/sebeevaluace/test/mbti-pro-mladez",
    component: _2d363220,
    name: "sebeevaluace-test-mbti-pro-mladez"
  }, {
    path: "/sebeevaluace/test/sebeevaluace",
    component: _d0234c82,
    name: "sebeevaluace-test-sebeevaluace"
  }, {
    path: "/sebeevaluace/test/mbti-pro-dospele/test",
    component: _c032f1fe,
    name: "sebeevaluace-test-mbti-pro-dospele-test"
  }, {
    path: "/sebeevaluace/test/mbti-pro-dospele/vysledek",
    component: _38c51750,
    name: "sebeevaluace-test-mbti-pro-dospele-vysledek"
  }, {
    path: "/sebeevaluace/test/mbti-pro-mladez/test",
    component: _594ef0e4,
    name: "sebeevaluace-test-mbti-pro-mladez-test"
  }, {
    path: "/sebeevaluace/test/mbti-pro-mladez/vysledek",
    component: _5b60509a,
    name: "sebeevaluace-test-mbti-pro-mladez-vysledek"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/vyhodnoceni",
    component: _11d3f825,
    name: "sebeevaluace-test-sebeevaluace-vyhodnoceni"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/co-chci/plat",
    component: _df6a7096,
    name: "sebeevaluace-test-sebeevaluace-co-chci-plat"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/co-chci/pracovni-podminky",
    component: _3a906f6c,
    name: "sebeevaluace-test-sebeevaluace-co-chci-pracovni-podminky"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/co-umim/aktivity",
    component: _5141040c,
    name: "sebeevaluace-test-sebeevaluace-co-umim-aktivity"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/co-umim/jazyky",
    component: _d370434e,
    name: "sebeevaluace-test-sebeevaluace-co-umim-jazyky"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/co-umim/kompetence",
    component: _dcf22114,
    name: "sebeevaluace-test-sebeevaluace-co-umim-kompetence"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/co-umim/vzdelani",
    component: _557519aa,
    name: "sebeevaluace-test-sebeevaluace-co-umim-vzdelani"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/moje-prace/kvalifikace-narocnost",
    component: _89a31b0a,
    name: "sebeevaluace-test-sebeevaluace-moje-prace-kvalifikace-narocnost"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/moje-prace/obor",
    component: _de9e7fbe,
    name: "sebeevaluace-test-sebeevaluace-moje-prace-obor"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/moje-prace/role",
    component: _722f8da1,
    name: "sebeevaluace-test-sebeevaluace-moje-prace-role"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/moje-prace/zakonem-pozadovana-praxe",
    component: _5204d35b,
    name: "sebeevaluace-test-sebeevaluace-moje-prace-zakonem-pozadovana-praxe"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/o-me/osobnost",
    component: _13127d3f,
    name: "sebeevaluace-test-sebeevaluace-o-me-osobnost"
  }, {
    path: "/sebeevaluace/test/sebeevaluace/o-me/zdravotni-omezeni",
    component: _0faaf8f2,
    name: "sebeevaluace-test-sebeevaluace-o-me-zdravotni-omezeni"
  }, {
    path: "/admin/CMS/page/:id?",
    component: _3c7fdc60,
    name: "admin-CMS-page-id"
  }, {
    path: "/admin/company/:id",
    component: _733ef430,
    name: "admin-company-id"
  }, {
    path: "/admin/segment/:id",
    component: _5182d29e,
    name: "admin-segment-id"
  }, {
    path: "/informace-o-trhu-prace/segment/:urlSlug?",
    component: _8a635014,
    name: "informace-o-trhu-prace-segment-urlSlug"
  }, {
    path: "/informace-o-trhu-prace/zamestnavatele/:companyId?",
    component: _43aed3f1,
    name: "informace-o-trhu-prace-zamestnavatele-companyId"
  }, {
    path: "/",
    component: _64951f52,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
