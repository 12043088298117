export const ITEMS = [
  { icon: 'apps', title: 'Homepage', to: '/' },
  { icon: 'apps', title: 'Admin', to: '/admin' },
  // { icon: 'apps', title: 'Podstránka', to: '/podstranka' },
  // { icon: 'file', title: 'CMS', to: {path:'/admin/CMS'} },
  { icon: 'supervised_user_circle', title: 'Uživatelé', to: { path: '/admin/uzivatel' } },
  { icon: 'file_copy', title: 'M4 Segementy', to: { path: '/admin/segment', query: { path: 'M4|Segmenty' } } },
  { icon: 'file_copy', title: 'M5 Stránky', to: { path: '/admin/CMS', query: { path: 'M5' } } },
  { icon: 'file_copy', title: 'Soubory', to: { path: '/admin/CMS/files' } },
  { icon: 'link', title: 'Užitečné odkazy', to: { path: '/admin/UsefulLinks' } },
  { icon: 'link', title: 'Profily zaměstnavatelů', to: { path: '/admin/company' } },
  { icon: 'link', title: 'Profese', to: { path: '/admin/profese' } },
  { icon: 'link', title: 'Command log', to: { path: '/admin/service/command_log' } }
]
