export const NAMESPACE = 'userProfessions'

export const ACTIONS = {}

export const MUTATIONS = {
  SET_RESULTS: 'SET_RESULTS',
  SET_LOADING: 'SET_LOADING'
}

export const mutations = {
  [MUTATIONS.SET_RESULTS](state, { avgRating, countOfUnits, topUnits }) {
    state[STATE.AVG_RATING] = avgRating
    state[STATE.COUNT_OF_UNITS] = countOfUnits
    state[STATE.TOP_UNITS] = topUnits
  },
  [MUTATIONS.SET_LOADING](state, isLoading) {
    state[STATE.IS_LOADING] = isLoading
  },
  _resetState(state) {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {}

export const STATE = {
  AVG_RATING: 'avgRating',
  COUNT_OF_UNITS: 'countOfUnits',
  TOP_UNITS: 'topUnits',
  IS_LOADING: 'IS_LOADING'
}

const getDefaultState = () => ({
  [STATE.AVG_RATING]: 0,
  [STATE.COUNT_OF_UNITS]: 0,
  [STATE.TOP_UNITS]: [],
  [STATE.IS_LOADING]: false
})

export const state = getDefaultState
