<template>
  <v-app dark class="jh-theme jh-theme--admin">
    <admin-drawer />
    <v-toolbar clipped-left fixed app>
      <v-toolbar-side-icon @click="toggleDrawer()" />
      <v-toolbar-title v-text="'Jobhub administrace'" />
      <v-spacer />
      <user-panel />
    </v-toolbar>
    <v-content>
      <v-container grid-list-sm fluid fill-height>
        <nuxt />
      </v-container>
    </v-content>
    <v-dialog
      :value="showLoginExpirationModal"
      max-width="320"
      @input="onLoginExpirationModal"
    >
      <v-card>
        <v-card-title class="headline">
          Přihlášení vypršelo
        </v-card-title>
        <v-card-text>
          <login-form />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click.native="logoutAndGoToHomepage"
          >
            Přejít na hlavní stránku
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import UserPanel from '~/components/User/Panel'
import AdminDrawer from '~/components/Admin/Drawer'
import LoginForm from '~/components/User/LoginForm'
import { STATE, MUTATIONS } from '~/store/index'
import { NAMESPACE as AUTH_NAMESPACE, STATE as AUTH_STATE } from '~/store/auth'

export default {
  components: { UserPanel, AdminDrawer,LoginForm },

  data() {
    return {
    }
  },
  computed: {
    showLoginExpirationModal() {
      return !this.$store.state[AUTH_NAMESPACE][
        AUTH_STATE.IS_TOKEN_NOT_EXPIRED
        ]
    },
  },
  methods: {
    onLoginExpirationModal(state) {
      if (state === false && this.showLoginExpirationModal === true) {
        this.logoutAndGoToHomepage()
      }
    },
    async logoutAndGoToHomepage() {
      await this.$auth.logout()
      this.$router.push('/')
    },
    toggleDrawer() {
      this.$store.commit(
        MUTATIONS.SET_ADMIN_DRAWER,
        !this.$store.state[STATE.isAdminDrawerOpen]
      )
    }
  }
}
</script>
