import createPersistedState from 'vuex-persistedstate'
import { NAMESPACE as MBTI_NAMESPACE, STATE as MBTI_STATE, CONTEXT as MBTI_CTX } from '~/store/mbti'
import { NAMESPACE as LISTS_NAMESPACE } from '~/store/lists'
import { NAMESPACE as AUTH_NAMESPACE, STATE as AUTH_STATE, MUTATIONS as AUTH_MUTATIONS } from '~/store/auth'
import {
  NAMESPACE as EVAL_NAMESPACE,
  STATE as EVAL_STATE,
  MUTATIONS as EVAL_MUTATIONS
} from '~/store/selfEvaluationTest'
import { STATE, MUTATIONS } from '~/store'

export default ({ store, app }) => {
  if (process.browser) {
    createPersistedState({
      paths: [STATE.currentVersion]
    })(store)

    // handle current version of application, if new version of app is released clear localstorage cache
    if (store.state[STATE.currentVersion] !== process.env.VERSION) {
      localStorage.clear()
      console.info('New version detected clear cache')
      store.commit(MUTATIONS.SET_CURRENT_VERSION, process.env.VERSION)
      window.location.reload()
    }

    // codes are preload with fetch in page nacist.vue, so this have to be checked
    let serverCode = store.state[EVAL_NAMESPACE][EVAL_STATE.EVALUATION_CODE]
    createPersistedState({
      key: EVAL_NAMESPACE,
      paths: [`${EVAL_NAMESPACE}.${EVAL_STATE.EVALUATION_CODE}`]
    })(store)
    if (serverCode !== null) {
      store.commit(`${EVAL_NAMESPACE}/${EVAL_MUTATIONS.SET_EVALUATION_CODE}`, serverCode)
    }

    let mbtiServerCode = JSON.parse(JSON.stringify(store.state[MBTI_NAMESPACE][MBTI_STATE.CODE]))
    createPersistedState({
      key: MBTI_NAMESPACE,
      paths: [`${MBTI_NAMESPACE}.${MBTI_STATE.CODE}`]
    })(store)
    if (mbtiServerCode[MBTI_CTX.TEEN] !== null) {
      store.commit(`${MBTI_NAMESPACE}/_setCode`, { code: mbtiServerCode[MBTI_CTX.TEEN], context: MBTI_CTX.TEEN })
    }
    if (mbtiServerCode[MBTI_CTX.ADULT] !== null) {
      store.commit(`${MBTI_NAMESPACE}/_setCode`, { code: mbtiServerCode[MBTI_CTX.ADULT], context: MBTI_CTX.ADULT })
    }

    createPersistedState({
      key: LISTS_NAMESPACE,
      paths: [LISTS_NAMESPACE]
    })(store)

    createPersistedState({
      key: AUTH_NAMESPACE,
      paths: [`${AUTH_NAMESPACE}.${AUTH_STATE.USER}`]
    })(store)

    if (!app.$auth.isLoggedIn) {
      store.commit(`${AUTH_NAMESPACE}/${AUTH_MUTATIONS.RESET_USER}`)
    }
  }
}
