import _isArray from 'lodash/isArray'

const cookieparser = process.server ? require('cookieparser') : undefined
import { NAMESPACE, MUTATIONS, ACTIONS, JWT_KEY, STATE } from '~/store/auth'
import { NAMESPACE as MBTI_NS, MUTATIONS as MBTI_MUT, CONTEXT as MBTI_CTX } from '~/store/mbti'
import { NAMESPACE as EVAL_NS } from '~/store/selfEvaluationTest'
import { NAMESPACE as PROFILE_NS } from '~/store/profile'

// based on symfony role_hierarchy
const ROLE_HIERARCHY = {
  ROLE_CONSULTANT: 'ROLE_USER',
  ROLE_ADMIN_ACCESS: ['ROLE_USER', 'ROLE_CONSULTANT'],
  ROLE_WORKER_UP: 'ROLE_ADMIN_ACCESS',
  ROLE_WORKER: ['ROLE_ADMIN_ACCESS', 'ROLE_WORKER_UP'],
  ROLE_SUPERVISOR: ['ROLE_WORKER', 'ROLE_WORKER_UP'],
  ROLE_ADMIN: ['ROLE_SUPERVISOR'],
  ROLE_SUPER_ADMIN: ['ROLE_ADMIN', 'ROLE_ALLOWED_TO_SWITCH']
}

/**
 * Go recursive in ROLE_HIERARCHY and check if user has needed role
 *
 * @param {string} roleToFind
 * @param {string} inRole
 * @returns {boolean}
 */
function hasRoleRoleRecursive(roleToFind, inRole) {
  for (const staticRole in ROLE_HIERARCHY) {
    if (inRole === staticRole) {
      if (_isArray(ROLE_HIERARCHY[staticRole])) {
        for (const inheritedRole of ROLE_HIERARCHY[staticRole]) {
          if (
            inheritedRole === roleToFind ||
            hasRoleRoleRecursive(roleToFind, inheritedRole)
          ) {
            return true
          }
        }
      } else {
        if (
          ROLE_HIERARCHY[staticRole] === roleToFind ||
          hasRoleRoleRecursive(roleToFind, ROLE_HIERARCHY[staticRole])
        ) {
          return true
        }
      }
    }
  }
  return false
}

/**
 * Class handles all user authentication and authorization in application
 * Behind this class is auth store which is storing all data and communicating with api
 */
class Auth
{
  constructor($store, router) {
    this.$store = $store
    this.router = router
    this.watch()
  }

  watch() {
    this.$store.watch(
      // wath user token for change this will detect changes on routes like logout and token expiration and redirect user
      state => state[NAMESPACE][STATE.TOKEN_USER],
      () => {
        if (this.isRouteAnonymous(this.router.currentRoute)) {
          return
        }
        if (
          !this.isLoggedIn &&
          !this.isAllowedToAccessRoute(this.router.currentRoute)
        ) {
          // logout action on non private route
          this.router.push('/')
          return
        }
        // refresh routes with user access
        this.router.go()
      },
      {
        deep: true
      }
    )

    this.$store.watch(
      state => state[NAMESPACE][STATE.USER],
      () => {
        console.log('USER CHANGED')
      }
    )


    if (process.browser) {
      // periodical check of token expiration
      window.setInterval(() => {
        this.$store.dispatch(`${NAMESPACE}/${ACTIONS.CHECK_TOKEN}`)
      }, 1000)
    }
  }

  isRouteAnonymous(route) {
    let permissions = this.getRoutePermissions(route)
    return permissions.length === 0
  }

  getRoutePermissions(route) {
    return [].concat(
      ...route.matched
        .filter(function (r) {
          return r.components.default.options.hasOwnProperty('AUTH_ROLE')
        })
        .map(r => {
          return r.components.default.options.AUTH_ROLE
        })
    )
  }

  isAllowedToAccessRoute(route) {
    const neededRoles = this.getRoutePermissions(route)
    if (neededRoles.length === 0) {
      return true
    }
    for (const role of neededRoles) {
      if (this.hasRole(role)) {
        return true
      }
    }
    return false
  }

  get isLoggedIn() {
    return this.$store.state[NAMESPACE][STATE.TOKEN_USER] !== null
  }

  get tokenUser() {
    return this.$store.state[NAMESPACE][STATE.TOKEN_USER]
  }

  get user() {
    return this.$store.state[NAMESPACE][STATE.USER]
  }

  get isTokenNotExpired() {
    if (!this.isLoggedIn) {
      return true
    }
    let expDate = new Date(this.tokenUser['exp'] * 1000)
    // console.log(this.tokenUser["exp"], expDate, expDate.toLocaleDateString())
    return expDate > new Date()
  }

  get hasUserAgreedWithCurrentTerms() {
    // console.log(
    //   this.user,
    //   this.user !== null
    //   // this.user.hasOwnProperty('confirmedCurrentTermsAgreement'),
    //   // this.user.confirmedCurrentTermsAgreement === false
    // )
    if (this.user !== null && this.user.hasOwnProperty('confirmedCurrentTermsAgreement') && this.user.confirmedCurrentTermsAgreement === false) {
      return false
    }
    return true
  }

  async login(username, password) {
    await this.$store.dispatch(`${NAMESPACE}/${ACTIONS.LOGIN}`, {
      username,
      password
    })

  }

  async logout() {
    await this.$store.dispatch(`${NAMESPACE}/${ACTIONS.LOGOUT}`)

    await Promise.all([
      this.$store.commit(`${MBTI_NS}/${MBTI_MUT.RESET_CODE}`, MBTI_CTX.ADULT),
      this.$store.commit(`${MBTI_NS}/${MBTI_MUT.RESET_CODE}`, MBTI_CTX.TEEN),
      this.$store.commit(`${EVAL_NS}/_resetState`),
      this.$store.commit(`${PROFILE_NS}/_resetState`)
    ])

  }

  async refreshUser() {
    await this.$store.dispatch(`${NAMESPACE}/${ACTIONS.ME}`)
  }

  forceUserExipire() {
    this.$store.commit(`${NAMESPACE}/${MUTATIONS.FORCE_EXPIRE_TOKEN}`)
  }

  hasRole(role) {
    if (!this.isLoggedIn) {
      return false
    }
    for (const userRole of this.tokenUser.roles) {
      if (userRole === role) {
        return true
      }
    }

    for (const userRole of this.tokenUser.roles) {
      if (hasRoleRoleRecursive(role, userRole)) {
        return true
      }
    }
    return false
  }
}

export default async ({ app, store, req, $axios }, inject) => {
  const $auth = new Auth(store, app.router)
  if (!process.browser) {
    // read jwt token on server side from session
    if (req.headers.cookie !== undefined) {
      const parsed = cookieparser.parse(req.headers.cookie)
      if (parsed[JWT_KEY] !== undefined) {
        await store.commit(
          `${NAMESPACE}/${MUTATIONS.SET_PAYLOAD_FROM_JWT}`,
          parsed[JWT_KEY]
        )
        if ($auth.isTokenNotExpired) {
          $axios.setHeader('X-CSRF-TOKEN', $auth.tokenUser.xcsrf)
        } else {
          delete parsed[JWT_KEY]
          req.headers.cookie = cookieparser.serialize(parsed)
        }
      }
    }
  } else {
    if ($auth.isLoggedIn) {
      $axios.setHeader('X-CSRF-TOKEN', $auth.tokenUser.xcsrf)
      if ($auth.user === null || $auth.user === undefined) {
        store.dispatch(`${NAMESPACE}/${ACTIONS.ME}`)
      }
    }
  }
  inject('auth', $auth)
}
