import Vue from 'vue'
import _isObject from 'lodash/isObject'

function plugin(Vue) {
  Vue.directive('jh-link', {
    inserted(el, binding, vnode) {
      let link = binding.value
      let newWindow = false

      if (link === undefined) {
        return
      }

      if (_isObject(link)) {
        if (link.link === undefined) {
          return
        }
        if (link.newWindow === true) {
          newWindow = true
        }
      }

      let click = function () {
        vnode.context.$router.push(link)
      }

      if (link.lastIndexOf('http', 0) === 0) {
        //is external link
        click = function () {
          if (newWindow === true) {
            let openWindow = window.open()
            openWindow.opener = null
            openWindow.location = link
          } else {
            window.location = link
          }
        }
      }

      ['click'].forEach(e => el.addEventListener(e, click))
    },
  })
}

Vue.use(plugin)
