import _debounce from 'lodash/debounce'
import _isArray from 'lodash/isArray'
import * as EmptyValues from '../utils/SelfEvaluationEmptyValues'

export const NAMESPACE = 'selfEvaluationTest'

export const TEST_PARTS = {
  ACTIVITY: 'activity',
  CERTIFICATE: 'certificate',
  COMPETENCE: 'competence',
  DEPARTMENT: 'department',
  EDUCATION: 'education',
  HEALTH: 'health',
  LANGUAGE: 'language',
  PERSONALITY: 'personality',
  PRACTISE: 'practise',
  ROLE: 'role',
  WAGE: 'wage',
  CONDITION: 'condition',
}

export const TEST_PARTS_CONFIG = {
  [TEST_PARTS.ACTIVITY]: {
    property: TEST_PARTS.ACTIVITY,
    formName: 'activity',
    empty: EmptyValues.ActivityDTO,
  },
  [TEST_PARTS.CERTIFICATE]: {
    property: TEST_PARTS.CERTIFICATE,
    formName: 'certificate_array',
    empty: Object.assign(
      JSON.parse(JSON.stringify(EmptyValues.CertificateArrayDTO)),
      {
        certificates: [JSON.parse(JSON.stringify(EmptyValues.CertificateDTO))]
      }
    ),
  },
  [TEST_PARTS.COMPETENCE]: {
    property: TEST_PARTS.COMPETENCE,
    formName: 'competences',
    formDataWrapper: 'competence_array',
    empty: [JSON.parse(JSON.stringify(EmptyValues.CompetenceDTO))],
  },
  [TEST_PARTS.DEPARTMENT]: {
    property: TEST_PARTS.DEPARTMENT,
    formName: 'departments',
    formDataWrapper: 'department_array',
    empty: [JSON.parse(JSON.stringify(EmptyValues.DepartmentDTO))],
  },
  [TEST_PARTS.EDUCATION]: {
    property: TEST_PARTS.EDUCATION,
    formName: 'education_array',
    empty: Object.assign(
      JSON.parse(JSON.stringify(EmptyValues.EducationArrayDTO)),
      {
        educations: [JSON.parse(JSON.stringify(EmptyValues.EducationDTO))]
      }
    ),
  },
  [TEST_PARTS.HEALTH]: {
    property: TEST_PARTS.HEALTH,
    formName: 'health',
    empty: JSON.parse(JSON.stringify(EmptyValues.HealthDTO)),
  },
  [TEST_PARTS.LANGUAGE]: {
    property: TEST_PARTS.LANGUAGE,
    formName: 'languages',
    formDataWrapper: 'language_array',
    empty: [
      Object.assign({}, EmptyValues.LanguageDTO, { language: { id: 'cs', title: 'Čeština - Čeština' }, isNative: true }),
      Object.assign({}, EmptyValues.LanguageDTO, { language: { id: 'en', title: 'Angličtina - English' }, })
    ],
  },
  [TEST_PARTS.PERSONALITY]: {
    property: TEST_PARTS.PERSONALITY,
    formName: 'personality',
    empty: JSON.parse(JSON.stringify(EmptyValues.PersonalityDTO)),
  },
  [TEST_PARTS.PRACTISE]: {
    property: TEST_PARTS.PRACTISE,
    formName: 'regulations',
    formDataWrapper: 'regulation_array',
    empty: [JSON.parse(JSON.stringify(EmptyValues.RegulationDTO))],
  },
  [TEST_PARTS.ROLE]: {
    property: TEST_PARTS.ROLE,
    formName: 'roles',
    formDataWrapper: 'role_array',
    empty: [JSON.parse(JSON.stringify(EmptyValues.RoleDTO))],
  },
  [TEST_PARTS.WAGE]: {
    property: TEST_PARTS.WAGE,
    formName: 'wage',
    empty: JSON.parse(JSON.stringify(EmptyValues.WageDTO)),
  },
  [TEST_PARTS.CONDITION]: {
    property: TEST_PARTS.CONDITION,
    formName: 'work_condition',
    empty: JSON.parse(JSON.stringify(EmptyValues.WorkConditionDTO)),
  },
}

export const ACTIONS = {
  SEND: 'send',
  SET_VALUE: 'setValue',
  LOAD_PART: 'loadPart',
  LOAD_STATUS: 'loadStatus',
  CHECK_CODE: 'checkCode'
}

export const MUTATIONS = {
  SET_LOADING: 'setLoading',
  UNSET_LOADING: 'unsetLoading',
  SET_VALUE: 'setValue',
  SET_EVALUATION_CODE: 'setEvaluationCode',
  SET_RESULT: 'setResult',
  SET_STATUS: 'setStatus'
}


export const mutations = {
  // Results
  [MUTATIONS.SET_RESULT](state, { avgRating, countOfUnits, topUnits }) {
    state[STATE.AVG_RATING] = avgRating
    state[STATE.COUNT_OF_UNITS] = countOfUnits
    state[STATE.TOP_UNITS] = topUnits
  },
  // test
  [MUTATIONS.SET_LOADING](state) {
    state[STATE.IS_LOADING] = true
  },
  [MUTATIONS.UNSET_LOADING](state) {
    state[STATE.IS_LOADING] = false
  },
  [MUTATIONS.SET_EVALUATION_CODE](state, code) {
    state[STATE.EVALUATION_CODE] = code
  },
  /**
   * Set value of desired part of test
   */
  [MUTATIONS.SET_VALUE](state, { partConfig, model }) {
    let emptyPart = JSON.parse(JSON.stringify(partConfig.empty))
    model = JSON.parse(JSON.stringify(model))
    if (_isArray(partConfig.empty)) {
      if (model.length === 0) {
        // set empty value when no data are set
        this._vm.$set(state, partConfig.property, emptyPart)
      } else {
        this._vm.$set(state, partConfig.property, model)
      }
    } else {
      this._vm.$set(state, partConfig.property, Object.assign({}, emptyPart, model))
    }
  },
  [MUTATIONS.SET_STATUS](state, { wholeStatus, part }) {
    state[STATE.IS_STATUS_LOADED] = true
    if (part !== undefined) {
      this._vm.$set(state[STATE.STATUS], part, true)
    }
    if (wholeStatus !== undefined) {
      this._vm.$set(state, STATE.STATUS, wholeStatus)
    }
  }
  ,
  // general
  _resetState(state) {
    Object.assign(state, getDefaultState())
  }
}

export const actions = {
  [ACTIONS.SET_VALUE]({ commit, dispatch }, { partConfig, model, skipSend = false }) {
    commit(MUTATIONS.SET_VALUE, { partConfig, model })
    if (skipSend === false) {
      dispatch(ACTIONS.SEND, { partConfig })
    }
  },
  async [ACTIONS.CHECK_CODE]({ commit }, code) {
    await this.$axios.$get('/jh-api/self-evaluation-code-check', {
      params: { code: code }
    })
    await commit(MUTATIONS.SET_EVALUATION_CODE, code)
  },
  async [ACTIONS.LOAD_PART]({ commit, state }, partConfig) {
    let code = state[STATE.EVALUATION_CODE]
    commit(MUTATIONS.SET_LOADING)
    if (this.$auth.isLoggedIn || code !== null) {
      try {
        const response = await this.$axios.$get(
          `/jh-api/self-evaluation/${partConfig.property}`,
          {
            params: { code }
          }
        )
        commit(MUTATIONS.SET_VALUE, { partConfig, model: response })
      } catch (e) {
        throw e
      }
    }
    commit(MUTATIONS.UNSET_LOADING)
  },
  async [ACTIONS.LOAD_STATUS]({ commit, state }) {
    let code = state[STATE.EVALUATION_CODE]
    if (state[STATE.IS_STATUS_LOADED] === true) {
      return
    }
    if (!this.$auth.isLoggedIn && code === null) {
      return
    }
    try {
      const response = await this.$axios.$get(
        '/jh-api/self-evaluation/status',
        {
          params: { code }
        }
      )
      commit(MUTATIONS.SET_STATUS, { wholeStatus: response })
    } catch (e) {
      throw e
    }
  },
  [ACTIONS.SEND]: _debounce(async function ({ state, commit }, { partConfig }) {
    let form = {}

    try {
      commit(MUTATIONS.SET_LOADING)

      if (partConfig.hasOwnProperty('formDataWrapper')) {
        form = {
          [partConfig.formDataWrapper]: { [partConfig.formName]: state[partConfig.property] }
        }
      } else {
        form = { [partConfig.formName]: state[partConfig.property] }
      }

      let code = state[STATE.EVALUATION_CODE]
      const response = await this.$axios.$put(
        '/jh-api/self-evaluation',
        form,
        { params: { code: code } }
      )
      // console.table(response.data.topUnits)
      // console.table({
      //   'Průměrné hodnocení povolání': response.data.avgRating,
      //   'Celkem povolání skóre > 0:': response.data.countOfUnits
      // })

      commit(MUTATIONS.SET_RESULT,
        {
          avgRating: response.stats.avgRating,
          countOfUnits: response.stats.countOfUnits,
          topUnits: response.stats.topUnits
        }
      )

      commit(MUTATIONS.SET_EVALUATION_CODE, response.code)
      commit(MUTATIONS.SET_STATUS, { part: partConfig.property })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.UNSET_LOADING)
    }
  }, 1000)
}


export const STATE = {
  // Results
  AVG_RATING: 'avgRating',
  COUNT_OF_UNITS: 'countOfUnits',
  TOP_UNITS: 'topUnits',
  IS_LOADING: 'isLoading',
  STATUS: 'status', // status if parts has any valid value
  IS_STATUS_LOADED: 'isStatusLoaded',
  // Test
  EVALUATION_CODE: 'EVALUATION_CODE',
}

const getDefaultState = () => ({
  // results
  [STATE.AVG_RATING]: 0,
  [STATE.COUNT_OF_UNITS]: 0,
  [STATE.TOP_UNITS]: [],
  [STATE.IS_LOADING]: false,

  [STATE.STATUS]: {
    activity: false,
    certificate: false,
    competence: false,
    department: false,
    education: false,
    health: false,
    language: false,
    personality: false,
    practise: false,
    role: false,
    wage: false,
    condition: false,
  },
  [STATE.IS_STATUS_LOADED]: false,

  // test
  [STATE.EVALUATION_CODE]: null,

  [TEST_PARTS.ACTIVITY]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.ACTIVITY].empty)),
  [TEST_PARTS.CERTIFICATE]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.CERTIFICATE].empty)),
  [TEST_PARTS.COMPETENCE]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.COMPETENCE].empty)),
  [TEST_PARTS.DEPARTMENT]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.DEPARTMENT].empty)),
  [TEST_PARTS.EDUCATION]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.EDUCATION].empty)),
  [TEST_PARTS.HEALTH]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.HEALTH].empty)),
  [TEST_PARTS.LANGUAGE]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.LANGUAGE].empty)),
  [TEST_PARTS.PERSONALITY]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.PERSONALITY].empty)),
  [TEST_PARTS.PRACTISE]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.PRACTISE].empty)),
  [TEST_PARTS.ROLE]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.ROLE].empty)),
  [TEST_PARTS.WAGE]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.WAGE].empty)),
  [TEST_PARTS.CONDITION]: JSON.parse(JSON.stringify(TEST_PARTS_CONFIG[TEST_PARTS.CONDITION].empty)),
})

export const state = getDefaultState
