import Vue from 'vue'
import {JOBHUB as JOBHUB_COLOR} from '~/utils/Colors'
// import LRU from 'lru-cache'
import Vuetify, {
  VApp,
  VAvatar,
  VAlert,
  VBadge,
  VCard,
  VCardText,
  VCardTitle,
  VCardActions,
  VCardMedia,
  VCombobox,
  VChip,
  VExpansionPanel,
  VFadeTransition,
  VDivider,
  VDataTable,
  VDialog,
  VCheckbox,
  VSelect,
  VNavigationDrawer,
  VFooter,
  VHover,
  VSubheader,
  VProgressCircular,
  VRadioGroup,
  VList,
  VListTile,
  VProgressLinear,
  VAutocomplete,
  VBtn,
  VIcon,
  VSlider,
  VToolbar,
  VTooltip,
  VJumbotron,
  VForm,
  VTextField,
  VSnackbar,
  VSpacer,
  VMenu,
  VWindow,
  VWindowItem,
  VResponsive,
  VImg,
  VFlex,
  VLayout,
  VContainer,
} from 'vuetify/lib'
// import { Ripple } from 'vuetify/lib/directives'
// const themeCache = LRU({
//   max: 10,
//   maxAge: 1000 * 60 * 60 // 1 hour
// })
Vue.use(Vuetify, {
  options: {
    // themeCache,
    minifyTheme: function (css) {
      return css.replace(/[\s|\r\n|\r|\n]/g, '')
    }
  },
  components: {
    VImg,
    VFlex,
    VContainer,
    VLayout,
    VApp,
    VAvatar,
    VAutocomplete,
    VAlert,
    VProgressCircular,
    VCard,
    VCardText,
    VCardTitle,
    VCardActions,
    VCardMedia,
    VDialog,
    VBadge,
    VCheckbox,
    VCombobox,
    VSlider,
    VExpansionPanel,
    VFadeTransition,
    VChip,
    VSubheader,
    VDivider,
    VDataTable,
    VNavigationDrawer,
    VProgressLinear,
    VFooter,
    VHover,
    VList,
    VListTile,
    VJumbotron,
    VSelect,
    VRadioGroup,
    VBtn,
    VIcon,
    VToolbar,
    VTooltip,
    VSnackbar,
    VSpacer,
    VForm,
    VTextField,
    VMenu,
    VWindow,
    VWindowItem,
    VResponsive
  },
  directives: {
    // Ripple
  },
  theme: {
    primary: '#115280',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',

    jhDark: JOBHUB_COLOR.jhDark,
    jhM4: JOBHUB_COLOR.jhM4,
    jhM3: JOBHUB_COLOR.jhM3,
    jhM2: JOBHUB_COLOR.jhM2,
    jhM1: JOBHUB_COLOR.jhM1,
    jhM5: JOBHUB_COLOR.jhM5,
    jhGrey: JOBHUB_COLOR.jhGrey,
    jhBg: JOBHUB_COLOR.jhBg,
    jhWhite: JOBHUB_COLOR.jhWhite,
  },
  iconfont: 'mdi',
  icons: {
    'menu': 'flaticon-menu',
    'expand': 'flaticon-expand',
    // 'complete': '',
    'cancel': 'flaticon-close',
    'close': 'flaticon-close',
    // 'delete': '', // delete (e.g. v-chip close)
    'clear': 'flaticon-close',
    'success': 'flaticon-check',
    'info': 'flaticon-error',
    'warning': 'flaticon-error',
    'error': 'flaticon-error',
    'prev': 'flaticon-back',
    'next': 'flaticon-forward',
    'checkboxOn': 'flaticon-check-1',
    'checkboxOff': 'flaticon-frame',
    // 'checkboxIndeterminate': '',
    // 'delimiter': '', // for carousel
    // 'sort': '',
    // 'subgroup': '',
    'dropdown': 'flaticon-menu-1',
    'radioOn': 'flaticon-radio',
    'radioOff': 'flaticon-circle',
    'edit': 'flaticon-edit',
  }
})
