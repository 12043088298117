export default function ({ app, $axios }) {

  $axios.setHeader('Accept', 'application/json')
  $axios.setHeader('X-Accept-Version', 'v1')
  $axios.setHeader('crossDomain', true)
  $axios.withCredentials = true

  $axios.onRequest(config => {
    console.log('Making request to ' + config.url)
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    console.error('Request failed', code)

    if (code === 401) {
      app.$auth.forceUserExipire()

      if (process.browser) {
        app.$toast.error('Nejste přihlášen')
      }

      return
    }

    if (error.response.data.message) {
      app.$toast.error(error.response.data.message)
    } else {
      if (code === 404) {
        app.$toast.error('Záznam nenalezen')
        return
      }
      app.$toast.error(`Neočekávaná chyba ${code}`)
    }
  })
}
