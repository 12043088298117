const CMS_STORAGE_URL = process.env.CMS_STORAGE

export default {
  functional: true,
  props:{
    user: Object, // this is $auth.user, but component can be functional this way
    xxx: String,
  },
  render(h,context) {
    if (context.props.user === undefined || context.props.user.avatar === null) {
      return h('img', {
        attrs: {
          src: '/avatar_nogender.png',
          alt: context.props.user === undefined ? 'Uživatel' : context.props.user.fullName
        }
      })
    }

    return h('img', {
      attrs: {
        src: `${CMS_STORAGE_URL}${context.props.user.avatar.fileName}`,
        alt: context.props.user.fullName
      }
    })
  }
}
