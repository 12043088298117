export const NAMESPACE = 'lists'

export const ACTIONS = {
  LOAD_LIST: 'LOAD_LIST'
}

export const MUTATIONS = {
  SET_LIST: 'SET_LIST',
  SET_LOADING: 'SET_LOADING'
}

export const GETTERS = {
  GET_LIST: 'GET_LIST'
}

export const STATE = {
  LISTS: 'LISTS',
  LOADING: 'LOADING'
}

export const state = () => ({
  [STATE.LISTS]: {},
  [STATE.LOADING]: {}
})

export const getters = {
  [GETTERS.GET_LIST](state) {
    return url => {
      return state[STATE.LISTS][url]
    }
  }
}

export const mutations = {
  [MUTATIONS.SET_LIST](state, { data, url }) {
    console.log(MUTATIONS.SET_LIST, data, url)
    this._vm.$set(state[STATE.LISTS], url, data)
  },
  [MUTATIONS.SET_LOADING](state, { url, isLoading }) {
    console.log(MUTATIONS.SET_LOADING, url)
    this._vm.$set(state[STATE.LOADING], url, isLoading)
  }
}

export const actions = {
  async [ACTIONS.LOAD_LIST]({ commit, getters }, { url }) {
    let cache = getters[GETTERS.GET_LIST](url)
    if (cache !== undefined) {
      return
    }
    try {
      const response = await this.$axios.$get(`/jh-api/${url}`)
      commit(MUTATIONS.SET_LIST, { data: response, url })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.SET_LOADING, { url, isLoading: false })
    }
  }
}
