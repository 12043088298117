import jwtDecode from 'jwt-decode'

export const JWT_KEY = 'BEARER'
export const NAMESPACE = 'auth'

export const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  CHECK_TOKEN: 'CHECK_TOKEN',
  ME: 'ME'
}

export const MUTATIONS = {
  SET_USER: 'SET_USER',
  SET_TOKEN_PAYLOAD: 'SET_TOKEN_PAYLOAD',
  SET_TOKEN_EXPIRED: 'SET_TOKEN_EXPIRED',
  SET_PAYLOAD_FROM_JWT: 'SET_PAYLOAD_FROM_JWT',
  FORCE_EXPIRE_TOKEN: 'FORCE_EXPIRE_TOKEN',
  RESET_USER: 'RESET_USER'
}

export const STATE = {
  USER: 'USER',
  TOKEN_USER: 'TOKEN_USER',
  TOKEN: 'TOKEN',
  IS_TOKEN_NOT_EXPIRED: 'IS_TOKEN_NOT_EXPIRED'
}

export const state = () => ({
  [STATE.TOKEN_USER]: null,
  [STATE.IS_TOKEN_NOT_EXPIRED]: true,
  [STATE.USER]: null
})

export const mutations = {
  [MUTATIONS.SET_USER](state, user) {
    this._vm.$set(state, STATE.USER, user)
    state[STATE.IS_TOKEN_NOT_EXPIRED] = true
    console.log(MUTATIONS.SET_USER, state[STATE.USER])
  },
  // only server side methods
  [MUTATIONS.SET_PAYLOAD_FROM_JWT](state, jwt) {
    state[STATE.TOKEN_USER] = jwtDecode(jwt)
    state[STATE.IS_TOKEN_NOT_EXPIRED] = true
  },
  [MUTATIONS.SET_TOKEN_PAYLOAD](state, payload) {
    state[STATE.TOKEN_USER] = payload
    state[STATE.IS_TOKEN_NOT_EXPIRED] = true
  },
  [MUTATIONS.RESET_USER](state) {
    state[STATE.USER] = null
    state[STATE.TOKEN_USER] = null
    state[STATE.IS_TOKEN_NOT_EXPIRED] = true
  },
  [MUTATIONS.FORCE_EXPIRE_TOKEN](state) {
    // expire token when user logout on different page and api returned 401
    if(state[STATE.TOKEN_USER] !== null) {
      state[STATE.TOKEN_USER]['exp'] = Math.floor(Date.now() / 1000)
    }
    state[STATE.IS_TOKEN_NOT_EXPIRED] = true
  },
  [MUTATIONS.SET_TOKEN_EXPIRED](state) {
    state[STATE.IS_TOKEN_NOT_EXPIRED] = false
  }
}

export const actions = {
  async [ACTIONS.LOGIN]({ commit, dispatch }, { username, password }) {
    try {
      const response = await this.$axios.$post('/jh-api/login_check', {
        username: username,
        password: password
      })
      commit(MUTATIONS.SET_TOKEN_PAYLOAD, response)
      this.$axios.setHeader('X-CSRF-TOKEN', this.$auth.tokenUser.xcsrf)
      dispatch(ACTIONS.ME)
    } catch (e) {
      commit(MUTATIONS.RESET_USER)
      throw e
    }
  },
  async [ACTIONS.ME]({ commit }) {
    // simple user profile information
    try {
      const response = await this.$axios.$get('/jh-api/me')
      commit(MUTATIONS.SET_USER, response)
    } catch (e) {
      commit(MUTATIONS.RESET_USER)
    }
  },
  async [ACTIONS.LOGOUT]({ commit }) {
    try {
      await this.$axios.$post('/jh-api/me/logout', {
        params: {
          _format: 'json'
        }
      })
      commit(MUTATIONS.RESET_USER)
    } catch (e) {
      throw e
    }
  },
  async [ACTIONS.CHECK_TOKEN]({ commit }) {
    if (this.$auth.isTokenNotExpired) {
      return
    }
    console.error('JWT token expired')
    commit(MUTATIONS.SET_TOKEN_EXPIRED)
  }
}
