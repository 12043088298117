import Vue from 'vue'

/**
 * Remove height of a single element
 *
 * @param element
 */
function removeHeight(element) {
  element.style.removeProperty('height')
}

/**
 * Remove height of each selector
 *
 * @param el
 * @param selectors
 */
function removeHeights(el, selectors) {
  [].forEach.call(selectors, selector => {
    const elements = el.querySelectorAll(selector)
    ;[].forEach.call(elements, element => {
      removeHeight(element)
    })
  })
}

/**
 * Get the min height for a set of elements
 *
 * @param elements
 *
 * @returns {number}
 */
function getMinHeight(elements) {
  let min = 0

  ;[].forEach.call(elements, element => {
    if (element.offsetHeight > min) {
      min = element.offsetHeight
    }
  })

  return min
}

/**
 * Set the height of a single selector
 *
 * @param el
 * @param selector
 */
function setHeight(el, selector) {
  // console.log('setHeight', selector)
  const elements = el.querySelectorAll(selector)
  const height = getMinHeight(elements)
  // console.log('height', height, elements)
  ;[].forEach.call(elements, element => {
    element.style.height = `${height}px`
  })
}

/**
 * Determine if the plugin should run for the current window size based on the provided values
 *
 * @param rules
 *
 * @returns {boolean}
 */
function shouldRun(rules) {
  let shouldRun = true
  rules.forEach(rule => {
    let min = 0
    let max = rule

    if (typeof rule === 'object') {
      [min, max] = rule
    }

    if (window.innerWidth > min && window.innerWidth < max) {
      shouldRun = false
    }
  })

  return shouldRun
}

/**
 * Match heights function
 *
 * @param el
 * @param selectors
 * @param disabled
 */
function matchHeights(el, selectors = [], disabled = []) {
  // Size each selector in turn
  removeHeights(el, selectors)

  // Check if the plugin should run
  if (!shouldRun(disabled)) return false

  // Size each provided selector
  selectors.forEach(selector => setHeight(el, selector))
}

function plugin(Vue, options = {}) {
  Vue.directive('match-heights', {
    bind(el, binding) {
      function matchHeightsFunc() {
        matchHeights(
          el,
          binding.value.el,
          binding.value.disabled || options.disabled || []
        )
      }

      matchHeightsFunc()
      window.addEventListener('resize', matchHeightsFunc)
      Vue.nextTick(matchHeightsFunc)
    },

    inserted(el, binding) {
      function matchHeightsFunc() {
        matchHeights(
          el,
          binding.value.el,
          binding.value.disabled || options.disabled || []
        )
      }

      // Handle images rendering
      [].forEach.call(document.querySelectorAll(binding.value.el), el => {
        [].forEach.call(el.querySelectorAll('img'), img => {
          img.addEventListener('load', matchHeightsFunc)
        })
      })

      // Bind custom events to recalculate heights
      el.addEventListener('matchheight', matchHeightsFunc, false)
      document.addEventListener('matchheight', matchHeightsFunc, false)
    },

    // eslint-disable-next-line no-unused-vars
    unbind(el, binding) {}
  })
}

Vue.use(plugin, { disabled: [768] })
