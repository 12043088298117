export const CertificateArrayDTO = {certificates:[],b02:0,}
export const CertificateDTO = {certificateGroup:null,certificates:[],otherCertificates:[],hasOtherCertificates:false,}
export const CompetenceDTO = {level:0,competenceGroup:null,}
export const DepartmentDTO = {departmentGroup:null,departments:[],}
export const EducationArrayDTO = {highestEducation:null,educations:[],}
export const EducationDTO = {badv:null,baso:null,kkov:[],}
export const LanguageDTO = {isNative:false,level:0,languageDomains:[],language:null,}
export const RegulationDTO = {practiseGroup:null,practises:[],otherPractises:[],hasOtherPractises:false,}
export const RoleDTO = {roleGroup:null,roles:[],}
export const ActivityDTO = {k1:false,k2:false,k3:false,k4:false,k5:false,k6:false,k7:false,k8:false,k9:false,k10:false,k11:false,k12:false,k13:false,k14:false,k15:false,k16:false,k17:false,k18:false,k19:false,k20:false,k21:false,k22:false,k23:false,}
export const HealthDTO = {healthConditions:[],hasAnyHealthCondition:null,}
export const PersonalityDTO = {riasec:null,a12:0,a08:0,a07:0,a10:0,a04:0,a11:0,a06:0,}
export const WageDTO = {wageOptimal:0,wageCompromise:0,wageRatio:90,}
export const WorkConditionDTO = {workConditions:[],}
