export const NAMESPACE = 'companies'

export const ACTIONS = {
  LOAD_COMPANIES: 'LOAD_COMPANIES',
  LOAD_JOBS: 'LOAD_JOBS'
}

export const MUTATIONS = {
  CLEAR_COMPANIES: 'CLEAR_COMPANIES',
  ADD_COMPANIES: 'ADD_COMPANIES',
  TOGGLE_COMPANY: 'TOGGLE_COMPANY',
  TOGGLE_COMPANY_LOADING: 'TOGGLE_COMPANY_LOADING',
  ADD_JOBS: 'ADD_JOBS',
  SET_LOADING: 'SET_LOADING'
}

export const STATE = {
  COMPANIES: 'COMPANIES',
  LOADING: 'LOADING',
  TOTAL_COMPANIES: 'TOTAL_COMPANIES'
}

export const GETTERS = {
  COMPANY_BY_ID: 'companyById',
}

export const state = () => ({
  [STATE.COMPANIES]: [],
  [STATE.LOADING]: false,
  [STATE.TOTAL_COMPANIES]: 0
})

export const getters = {
  [GETTERS.COMPANY_BY_ID]: (state) => (companyId) => {
    return state[STATE.COMPANIES].find(company => company.companyId === companyId)
  }
}

export const mutations = {
  [MUTATIONS.CLEAR_COMPANIES](state) {
    state[STATE.COMPANIES] = []
  },
  [MUTATIONS.ADD_COMPANIES](state, { companies, clear, total }) {
    if (clear === true) {
      state[STATE.COMPANIES] = []
    }
    state[STATE.COMPANIES].push(...companies)
    state[STATE.TOTAL_COMPANIES] = total
  },
  [MUTATIONS.SET_LOADING](state, isLoading) {
    state[STATE.LOADING] = isLoading
  },
  [MUTATIONS.TOGGLE_COMPANY](state, companyId) {
    state[STATE.COMPANIES] = [
      ...state[STATE.COMPANIES].map(company => {
        if (company.companyId === companyId) {
          company.expanded = !company.expanded
        }
        return company
      })
    ]
  },
  [MUTATIONS.TOGGLE_COMPANY_LOADING](state, companyId) {
    state[STATE.COMPANIES] = [
      ...state[STATE.COMPANIES].map(company => {
        if (company.companyId === companyId) {
          company.loading = !company.loading
        }
        return company
      })
    ]
  },
  [MUTATIONS.ADD_JOBS](state, { companyId, units, clear, total }) {
    state[STATE.COMPANIES] = [
      ...state[STATE.COMPANIES].map(company => {
        if (company.companyId === companyId) {
          company.unitsTotal = total
          if (clear === true) {
            company.units = units
          } else {
            company.units.push(units)
          }
        }
        return company
      })
    ]
  }
}

export const actions = {
  async [ACTIONS.LOAD_JOBS]({ commit, getters }, { companyId, clear = false }) {
    commit(MUTATIONS.TOGGLE_COMPANY, companyId)
    commit(MUTATIONS.TOGGLE_COMPANY_LOADING, companyId)
    let company = getters[GETTERS.COMPANY_BY_ID](companyId)
    if (company.expanded === false) {
      return
    }
    try {
      const response = await this.$axios.get(`/jh-api/company/${companyId}/jobs`)
      commit(MUTATIONS.ADD_JOBS, { companyId: companyId, units: response.data, clear, total: parseInt(response.headers['x-count']) })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.TOGGLE_COMPANY_LOADING, companyId)
    }
  },
  async [ACTIONS.LOAD_COMPANIES]({ commit, state }, { query, clear = false }) {
    commit(MUTATIONS.SET_LOADING, true)
    if (clear === true) {
      commit(MUTATIONS.CLEAR_COMPANIES)
    }
    try {
      const response = await this.$axios.get('/jh-api/company', { params: Object.assign({}, query, { offset: state[STATE.COMPANIES].length }) })
      commit(MUTATIONS.ADD_COMPANIES, { companies: response.data, clear, total: parseInt(response.headers['x-count']) })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.SET_LOADING, false)
    }
  }
}
