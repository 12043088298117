import Vue from 'vue'
const WindowInstanceMap = new Vue({
  data() {
    return {
      scrollY: 0
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      // Debounce this
      window.requestAnimationFrame(() => {
        this.scrollY = window.scrollY
      })
    })
  }
})
// Inject plugin as Vue.prototype.$window
export default (context, inject) => {
  inject('window', WindowInstanceMap)
}
