<template>
  <v-form
    ref="form"
    v-model="isValid"
    lazy-validation
  >
    <v-text-field
      v-model="username"
      :rules="usernameRules"
      label="Email"
      required
      validate-on-blur
    />
    <v-text-field
      v-model="password"
      :append-icon="isPasswordHidden ? 'flaticon-eye' : 'flaticon-off'"
      :type="isPasswordHidden ? 'password' : 'text'"
      label="Heslo"
      :rules="passwordRules"
      @keydown.enter="login"
      @click:append="() => (isPasswordHidden = !isPasswordHidden)"
    />
    <v-flex text-xs-right>
      <v-btn
        :disabled="!isValid || isLoading"
        color="jhM3"
        class="jh-white--text"
        :loading="isLoading"
        @click="login"
      >
        Přihlásit se
      </v-btn>
    </v-flex>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      isValid: false,
      isPasswordHidden: true,
      isLoading: false,
      password: '',
      username: '',
      usernameRules: [
        v => !!v || 'Email je povinný',
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Nepatný email'
      ],
      passwordRules: [v => !!v || 'Bez hesla to nepůjde :(']
    }
  },
  methods: {
    async login() {
      if (!this.isValid || !this.$refs.form.validate()) {
        return
      }
      try {
        this.isLoading = true
        await this.$auth.login(this.username, this.password)
        this.$emit('success')
      } catch {
        this.$toast.error('Neplatné uživatelské jméno nebo heslo.')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
