<template>
  <v-dialog
    v-model="isTermsDialogOpen"
    max-width="600"
    :persistent="!allowCancel"
  >
    <v-card>
      <v-card-title class="headline" primary-title>
        {{ title }}
      </v-card-title>
      <v-card-text v-if="isTermsLoading">
        <logo-loading></logo-loading>
      </v-card-text>
      <template v-else>
        <template v-if="showChanges && terms.changes">
          <v-expansion-panel>
            <v-expansion-panel-content :value="true">
              <template v-slot:header>
                <div>Změny v podmínkách</div>
              </template>
              <v-card>
                <v-card-text class="pb-0" v-html="terms.changes"></v-card-text>
              </v-card>
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <template v-slot:header>
                <div>Kompletní podmínky</div>
              </template>
              <v-card>
                <v-card-text class="pb-0" v-html="terms.terms"></v-card-text>
                <v-card-text class="pt-0">
                  {{ getDate(terms.validFrom) }}
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
        <template v-else>
          <v-card-text class="pb-0" v-html="terms.terms"></v-card-text>
          <v-card-text class="pt-0">
            {{ getDate(terms.validFrom) }}
          </v-card-text>
        </template>
        <v-card-text>
          <v-btn flat color="jhM1" :loading="isTermsConfirmCancelPending" @click="acceptTerms">
            Souhlasím
          </v-btn>
          <v-btn v-if="allowDecline" flat color="jhM2" :loading="isTermsConfirmCancelPending" @click="declineTerms">
            Odhlásit se
          </v-btn>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
  import LogoLoading from '@/components/logoLoading'

  export default {
    components: { LogoLoading },
    props:{
      title: String,
      allowCancel: {
        type: Boolean,
        default: true
      },
      allowDecline: {
        type: Boolean,
        default: false
      },
      showChanges: Boolean,
    },
    data() {
      return {
        isTermsConfirmCancelPending: false,
        isTermsDialogOpen: false,
        isTermsLoading: false,
        terms: {},
      }
    },
    methods: {
      getDate(dateString) {
        return new Date(dateString).toLocaleString('cs-CZ', { year: 'numeric', month: 'long', day: 'numeric' })
      },
      acceptTerms() {
        this.isTermsConfirmCancelPending = true
        this.$emit('accept')
      },
      declineTerms(){
        this.isTermsConfirmCancelPending = true
        this.$emit('decline')
      },
      cancelTermsConfirmCancelPending(){
        this.isTermsConfirmCancelPending = false
      },
      close(){
        this.isTermsConfirmCancelPending = false
        this.isTermsDialogOpen = false
      },
      async open() {
        this.isTermsDialogOpen = true
        this.isTermsLoading = true
        try {
          let terms = await this.$axios.$get('/jh-api/terms')
          this.terms = terms
        } catch (e) {
          // skip catch
        } finally {
          this.isTermsLoading = false
        }
      },
    }
  }
</script>
