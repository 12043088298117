<template>
  <v-toolbar
    :class="{
      transparent: isHomepage && !isScrolled,
      'elevation-1': !isHomepage || isScrolled
    }"
    app
    fixed
    dense
    flat
    :height="$vuetify.breakpoint.mdAndDown ? '0px' : '50px'"
    extension-height="100px"
    class="top"
  >
    <v-layout row class="hidden-md-and-down">
      <v-container>
        <v-layout align-center justify-center row>
          <v-toolbar-title class="top">
            <nuxt-link to="/" title="JOBHUB">
              <v-icon color="jh-white">
                flaticon-road
              </v-icon>
            </nuxt-link>
          </v-toolbar-title>
          <v-toolbar-title class="top">
            <nuxt-link to="/sebeevaluace" class="mod--m1">
              Sebeevaluační nástroj
            </nuxt-link>
          </v-toolbar-title>
          <v-toolbar-title v-for="link in LINKS" :key="link.title" class="top">
            <nuxt-link v-if="link.link" :to="link.link" :class="link.class">
              {{ link.title }}
            </nuxt-link>
            <a v-else :href="link.href" :class="link.class">
              {{ link.title }}
            </a>
          </v-toolbar-title>
          <v-toolbar-title class="top">
            <nuxt-link to="/informace-o-trhu-prace" class="mod--m4">
              Informace o trhu práce
            </nuxt-link>
          </v-toolbar-title>
          <v-toolbar-title class="top">
            <nuxt-link v-if="$auth.hasRole('ROLE_WORKER_UP')" to="/informace-pro-up" class="mod--m5">
              Informace pro ÚP
            </nuxt-link>
          </v-toolbar-title>
        </v-layout>
      </v-container>
    </v-layout>
    <template slot="extension">
      <v-layout row>
        <v-flex xs12>
          <v-container>
            <!--Keep it to match size of container-->
            <v-layout row>
              <v-flex lg12 xl8 offset-xl2>
                <v-layout align-center justify-center row>
                  <nuxt-link to="/">
                    <v-toolbar-title class="align-self-center" style="display: flex;">
                      <logo class="top-logo" :keep-default="isHomepage && !isScrolled" />
                    </v-toolbar-title>
                  </nuxt-link>
                  <v-spacer />
                  <user-panel />
                  <v-menu
                    offset-y
                    bottom
                    left
                    :close-on-content-click="!$auth.hasRole('ROLE_ADMIN_ACCESS')"
                  >
                    <v-toolbar-side-icon slot="activator" class="hidden-lg-and-up"></v-toolbar-side-icon>
                    <v-list dense>
                      <template v-if="$auth.hasRole('ROLE_ADMIN_ACCESS')">
                        <v-list-group
                          no-action
                        >
                          <template v-slot:activator>
                            <v-list-tile>
                              <v-list-tile-content>
                                <v-list-tile-title>JOBHUB</v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                          <template v-for="link in LINKS">
                            <v-list-tile :key="link.title" :to="link.link" :href="link.href">
                              <v-list-tile-title>{{ link.title }}</v-list-tile-title>
                            </v-list-tile>
                          </template>
                        </v-list-group>
                        <v-list-group
                          no-action
                        >
                          <template v-slot:activator>
                            <v-list-tile>
                              <v-list-tile-content>
                                <v-list-tile-title>ADMINISTRACE</v-list-tile-title>
                              </v-list-tile-content>
                            </v-list-tile>
                          </template>
                          <template v-for="link in ADMIN_MENU_ITEMS">
                            <v-list-tile :key="link.title" :to="link.to">
                              <v-list-tile-title>{{ link.title }}</v-list-tile-title>
                            </v-list-tile>
                          </template>
                        </v-list-group>
                      </template>
                      <template v-else>
                        <v-list-tile to="/sebeevaluace">
                          <v-list-tile-title>Sebeevaluační nástroj</v-list-tile-title>
                        </v-list-tile>
                        <template v-for="link in LINKS">
                          <v-list-tile :key="link.title" :to="link.link" :href="link.href">
                            <v-list-tile-title>{{ link.title }}</v-list-tile-title>
                          </v-list-tile>
                        </template>
                        <v-list-tile to="/informace-o-trhu-prace">
                          <v-list-tile-title>Informace o trhu práce</v-list-tile-title>
                        </v-list-tile>
                        <v-list-tile v-if="$auth.hasRole('ROLE_WORKER_UP')" to="/informace-pro-up">
                          <v-list-tile-title>Informace pro ÚP</v-list-tile-title>
                        </v-list-tile>
                      </template>
                    </v-list>
                  </v-menu>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </template>
  </v-toolbar>
</template>
<script>
  import { ITEMS as ADMIN_MENU_ITEMS } from '~/components/Admin/AdminMenuItems'
  import UserPanel from '~/components/User/Panel'
  import Logo from '~/components/logo'
  import { STATE, MUTATIONS } from '~/store/index'

  export default {
    components: {
      UserPanel,
      Logo
    }
    ,
    props: {
      isHomepage: Boolean
    }
    ,
    data() {
      // TODO: remove link with merge of m2/m3
      let links = [
        {
          href: `${process.env.M3LINK}zivotni-situace`,
          title: 'Průvodce životními situacemi',
          class: 'mod--m2'
        }, {
          href: process.env.M3LINK,
          title: 'Katalog kariérových poradců',
          class: 'mod--m3'
        }, {
          href: `${process.env.M3LINK}spolecnosti`,
          title: 'Katalog poradenských organizací',
          class: 'mod--m3'
        }
      ]

      if (process.env.NODE_ENV !== 'production') {
        links = [
          {
            link: '/pruvodce-zivotnimi-situacemi',
            title: 'Průvodce životními situacemi',
            class: 'mod--m2'
          }, {
            link: '/katalog-poradcu',
            title: 'Katalog kariérových poradců',
            class: 'mod--m3'
          }, {
            href: 'http://katalog.jhdev/spolecnosti',
            title: 'Katalog poradenských organizací',
            class: 'mod--m3'
          }
        ]
      }

      return {
        LINKS: links,
        ADMIN_MENU_ITEMS
      }
    }
    ,
    computed: {
      isScrolled() {
        if (this.$window === undefined) {
          return 0
        }
        return this.$window.scrollY !== 0
      }
    }
    ,
    methods: {
      toggleDrawer() {
        this.$store.commit(
          MUTATIONS.SET_ADMIN_DRAWER,
          !this.$store.state[STATE.isAdminDrawerOpen]
        )
      }
    }
  }
</script>
<style lang="stylus">
  @import '~vuetify/src/stylus/settings/_variables.styl'
  .top-logo
    width 250px
    @media $display-breakpoints.sm-and-down
      width: 100%
</style>
