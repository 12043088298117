<template>
  <layout>
    <v-container
      grid-list-md
      class="jh-h-100"
    >
      <v-layout
        row
        wrap
        align-space-around
      >
        <v-flex
          v-show="!$auth.isLoggedIn && evaluationCode !== null"
          xs12
          offset-xl2
          xl8
        >
          <v-card>
            <v-alert
              :value="true"
              type="info"
            >
              Váš test je dočasně uložen pod kódem
              <strong>{{ evaluationCode }}</strong>. Test bude po uplynutí jednoho měsíce automaticky smazán, kód si zaznamenejte pro pokračování v budoucnu nebo zadejte <a class="font-weight-bold" @click="$refs.setEmailDialog.open()">váš email</a>.
            </v-alert>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          md3
          offset-xl2
          xl2
        >
          <v-card class="mb-2">
            <v-list class="v-list--orange">
              <v-list-group
                v-for="item in menuItems"
                :key="item.title"
                v-model="item.active"
                :value="$vuetify.breakpoint.mdAndUp"
                no-action
                append-icon
              >
                <v-list-tile slot="activator">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile
                  v-for="child in item.child"
                  :key="child.title"
                  active-class="orange--text"
                  :to="child.path"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ child.title }}
                    </v-list-tile-title>
                    <v-icon
                      v-if="testStatus[child.partName] !== undefined && testStatus[child.partName] === true"
                      color="orange"
                    >
                      flaticon-check
                    </v-icon>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>
              <template v-show="evaluationCode !== null || $auth.isLoggedIn">
                <v-divider></v-divider>
                <v-list-group
                  no-action
                  append-icon
                >
                  <v-list-tile
                    slot="activator"
                    to="/sebeevaluace/test/sebeevaluace/vyhodnoceni"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title>Vyhodnocení testu</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list-group>
              </template>
            </v-list>
          </v-card>
          <v-card v-if="!isResultPage &&evaluationStore[EVAL_STATE.COUNT_OF_UNITS] > 0">
            <v-card-text>
              <v-list>
                <v-list-tile>
                  <!-- prettier-ignore -->
                  <v-list-tile-avatar v-if="evaluationStore[EVAL_STATE.IS_LOADING]">
                    <v-btn
                      :loading="true"
                      flat
                    />
                  </v-list-tile-avatar>
                  <v-list-tile-avatar
                    v-else
                    class="hidden-md-and-up"
                  >
                    <v-menu offset-y>
                      <v-btn
                        slot="activator"
                        icon
                      >
                        <v-icon>flaticon-forward</v-icon>
                      </v-btn>
                      <template v-if="
                        !isResultPage &&
                          evaluationStore[EVAL_STATE.COUNT_OF_UNITS] > 0
                      "
                      >
                        <v-list>
                          <v-list-tile
                            v-for="unit in evaluationStore[EVAL_STATE.TOP_UNITS]"
                            :key="unit.urlSlug"
                          >
                            <v-list-tile-avatar>
                              <v-progress-circular
                                :rotate="-90"
                                :size="100"
                                :width="15"
                                :value="getUnitPercentMatch(unit)"
                                color="jhM4"
                              >
                                {{ getUnitPercentMatch(unit) }}
                              </v-progress-circular>
                            </v-list-tile-avatar>
                            <v-list-tile-title> {{ unit.title }}</v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </template>
                    </v-menu>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    Prúběžné výsledky:
                    {{ evaluationStore[EVAL_STATE.COUNT_OF_UNITS] }} povolání
                  </v-list-tile-title>
                </v-list-tile>
                <template>
                  <v-list-tile
                    v-for="unit in evaluationStore[EVAL_STATE.TOP_UNITS]"
                    :key="unit.urlSlug"
                    class="hidden-sm-and-down"
                  >
                    <v-list-tile-avatar>
                      <v-progress-circular
                        :rotate="-90"
                        :size="100"
                        :width="15"
                        :value="getUnitPercentMatch(unit)"
                        color="jhM4"
                      >
                        {{ getUnitPercentMatch(unit) }}
                      </v-progress-circular>
                    </v-list-tile-avatar>
                    <v-list-tile-title> {{ unit.title }}</v-list-tile-title>
                  </v-list-tile>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          md9
          xl6
        >
          <nuxt />
        </v-flex>
      </v-layout>
    </v-container>
    <set-email-dialog ref="setEmailDialog" url="/jh-api/self-evaluation-set-email" :code="evaluationCode" @success="onEmailSuccessSet"></set-email-dialog>
  </layout>
</template>

<script>
import Layout from '~/components/layout/page-layout'
import LayoutHeadMixin from '~/components/LayoutHeadMixin'
import SetEmailDialog from '~/components/sebeevaluace/SetEmailDialog'
import {
  NAMESPACE as EVAL_NAMESPACE,
  STATE as EVAL_STATE,
  TEST_PARTS
} from '~/store/selfEvaluationTest'

export default {
  components: { Layout,SetEmailDialog },
  mixins: [LayoutHeadMixin],
  head() {
    return {
      title: 'Sebeevaluační test | JOBHUB',
      description: 'Na následujících stránkách si můžete vytvořit vlastní profil, na jehož základě Vám navrhneme vhodná povolání, která se vyskytují na trhu práce v České republice. Jak to funguje? ',
    }
  },
  data() {
    return {
      showCodeAlert: true,
      EVAL_STATE,
      menuItems: [
        {
          title: 'O mě',
          active: false,
          path: '/sebeevaluace/test/sebeevaluace/o-me/osobnost',
          child: [
            {
              title: 'Osobnost',
              path: '/sebeevaluace/test/sebeevaluace/o-me/osobnost',
              partName: TEST_PARTS.PERSONALITY,
              active: false
            },
            {
              title: 'Zdravotní omezení',
              path: '/sebeevaluace/test/sebeevaluace/o-me/zdravotni-omezeni',
              partName: TEST_PARTS.HEALTH,
              active: false
            }
          ]
        },
        {
          title: 'Moje práce',
          active: false,
          path: '/sebeevaluace/test/sebeevaluace/moje-prace/obor',
          child: [
            {
              title: 'Obor',
              path: '/sebeevaluace/test/sebeevaluace/moje-prace/obor',
              partName: TEST_PARTS.DEPARTMENT,
              active: false
            },
            {
              title: 'Role',
              path: '/sebeevaluace/test/sebeevaluace/moje-prace/role',
              partName: TEST_PARTS.ROLE,
              active: false
            },
            {
              title: 'Zákonem požadovaná praxe',
              path:
                '/sebeevaluace/test/sebeevaluace/moje-prace/zakonem-pozadovana-praxe',
              partName: TEST_PARTS.PRACTISE,
              active: false
            },
            {
              title: 'Kvalifikace a náročnost',
              path:
                '/sebeevaluace/test/sebeevaluace/moje-prace/kvalifikace-narocnost',
              partName: TEST_PARTS.CERTIFICATE,
              active: false
            }
          ]
        },
        {
          title: 'Co umím',
          active: false,
          path: '/sebeevaluace/test/sebeevaluace/co-umim/vzdelani',
          child: [
            {
              title: 'Vzdělání',
              path: '/sebeevaluace/test/sebeevaluace/co-umim/vzdelani',
              partName: TEST_PARTS.EDUCATION,
              active: false
            },
            {
              title: 'Jazyky',
              path: '/sebeevaluace/test/sebeevaluace/co-umim/jazyky',
              partName: TEST_PARTS.LANGUAGE,
              active: false
            },
            {
              title: 'Aktivity',
              path: '/sebeevaluace/test/sebeevaluace/co-umim/aktivity',
              partName: TEST_PARTS.ACTIVITY,
              active: false
            },
            {
              title: 'Kompetence',
              path: '/sebeevaluace/test/sebeevaluace/co-umim/kompetence',
              partName: TEST_PARTS.COMPETENCE,
              active: false
            }
          ]
        },
        {
          title: 'Co chci',
          active: false,
          path: '/sebeevaluace/test/sebeevaluace/co-chci/plat',
          child: [
            {
              title: 'Plat',
              path: '/sebeevaluace/test/sebeevaluace/co-chci/plat',
              active: false,
              partName: TEST_PARTS.WAGE
            },
            {
              title: 'Pracovní podmínky',
              path: '/sebeevaluace/test/sebeevaluace/co-chci/pracovni-podminky',
              partName: TEST_PARTS.CONDITION,
              active: false
            }
          ]
        }
      ]
    }
  },
  computed: {
    evaluationStore() {
      return this.$store.state[EVAL_NAMESPACE]
    },
    evaluationCode() {
      return this.evaluationStore[EVAL_STATE.EVALUATION_CODE]
    },
    testStatus() {
      return this.evaluationStore[EVAL_STATE.STATUS]
    },
    isResultPage() {
      return (
        this.$route.fullPath === '/sebeevaluace/test/sebeevaluace/vyhodnoceni'
      )
    }
  },
  watch: {
    $route() {
      this.refreshActive()
    }
  },
  mounted() {
    this.refreshActive()
  },
  methods: {
    onEmailSuccessSet(){

    },
    getUnitPercentMatch(unit) {
      let onePct = 100 / parseFloat(unit.maxScore)
      let pct = Math.round(onePct * unit.rating)
      return pct > 100 ? 100 : pct
    },
    refreshActive() {
      for (let item of this.menuItems) {
        if (item.path && item.path === this.$route.path) {
          item.active = true
        }
        for (let child of item.child) {
          if (child.path && child.path === this.$route.path) {
            child.active = true
            item.active = true
          }
        }
      }
    }
  }
}
</script>
