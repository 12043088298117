import { STATE } from '~/store'

export default {
  head() {
    let faviconPath = '/favicon/'
    let msTileColor = '#da532c'
    if (this.logoConfig !== null) {
      msTileColor = this.logoConfig.color
      if (this.logoConfig.mode !== null) {
        faviconPath += this.logoConfig.mode + '/'
      }
    }
    return {
      meta: [
        { name: 'msapplication-TileColor', content: msTileColor },
        { name: 'theme-color', content: '#ffffff' },
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: `${faviconPath}favicon.ico` },
        { rel: 'apple-touch-icon', sizes: '180x180', href: `${faviconPath}apple-touch-icon.png` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${faviconPath}favicon-32x32.png` },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${faviconPath}favicon-16x16.png` },
        { rel: 'manifest', href: `${faviconPath}site.webmanifest` },
        { rel: 'mask-icon', href: `${faviconPath}safari-pinned-tab.svg`, color: '#5bbad5' },
      ],
    }
  },
  computed: {
    logoConfig() {
      return this.$store.state[STATE.LOGO]
    },
  }
}
