<template>
  <v-app :class="themeClass" class="jh-theme">
    <slot name="custom-content"></slot>
    <toolbar :is-homepage="isHomepage" />
    <v-content>
      <slot />
    </v-content>
    <v-footer
      :fixed="false"
      height="auto"
      style="position: relative;"
      :style="footerBackgroundColorStyle"
    >
      <!--  Crap thing to be able to set footer background color  -->
      <div
        :style="darkSection"
        style="width: 100%;"
        class="crop-triangle --top --reverse ie11-fix-footer"
      >
        <v-container grid-list-xl>
          <v-layout row wrap>
            <v-flex xs12 md10 offset-md1 lg8 offset-lg2>
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs-12
                  md6
                  lg3
                >
                  <h3>JOBHUB</h3>
                  <ul>
                    <li class="link" @click="openLoginModal">
                      Přihlášení
                    </li>
                    <!--                <li>Co je JOBHUB</li>-->
                    <li class="link" @click="redirectToM3('')">
                      Popis kariérového poradenství
                    </li>
                    <!--<li>Novinky</li>-->
                    <!--<li>Případové studie</li>-->
                    <li class="link">
                      <a href="/JOBHUB-Zasady-ochrany-osobnich-udaju.pdf" target="_blank">Zásady ochrany osobních údajů</a>
                    </li>
                    <nuxt-link tag="li" class="link" to="/dokumenty">
                      Dokumenty
                    </nuxt-link>
                  </ul>
                </v-flex>
                <v-flex
                  xs-12
                  md6
                  lg3
                >
                  <h3 class="link" @click="redirectToM3('')">
                    PRO KARIÉROVÉ PORADCE
                  </h3>
                  <ul>
                    <li class="link" @click="redirectToM3('register')">
                      Registrace nového poradce
                    </li>
                    <li class="link" @click="redirectToM3('kazuistiky')">
                      Databáze kazuistik z praxe
                    </li>
                    <li class="link" @click="redirectToM3('methods')">
                      Databáze kariérových metod
                    </li>
                  </ul>
                </v-flex>
                <v-flex
                  xs-12
                  md6
                  lg3
                >
                  <h3>PRO KLIENTY</h3>
                  <ul>
                    <li class="link" @click="redirectToM3('poradci')">
                      Databáze kariérových poradců
                    </li>
                    <li class="link" @click="redirectToM3('spolecnosti')">
                      Databáze poradenských organizací
                    </li>
                    <nuxt-link tag="li" class="link" to="/sebeevaluace">
                      Sebeevaluační nástroj
                    </nuxt-link>
                  </ul>
                </v-flex>
                <v-flex
                  xs-12
                  md6
                  lg3
                >
                  <h3>PRO FIRMY</h3>
                  <ul>
                    <nuxt-link tag="li" class="link" to="/informace-o-trhu-prace/volna-mista">
                      Vypisování volných míst
                    </nuxt-link>

                    <!--                <li>Databáze uchazečů o práci</li>-->

                    <!--                <li>Služby kariérních poradců pro firmy</li>-->
                  </ul>
                </v-flex>
                <v-flex
                  xs12
                  class="text-xs-center"
                >
                  <span>
                    @Copyright {{ currentYear }} - Ministerstvo práce a sociálních věcí
                  </span>
                  <br />
                  <span> {{ version }} </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-footer>
    <v-dialog
      :value="showLoginExpirationModal"
      max-width="320"
      @input="onLoginExpirationModal"
    >
      <v-card>
        <v-card-title class="headline">
          Přihlášení vypršelo
        </v-card-title>
        <v-card-text>
          <login-form />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click.native="logoutAndGoToHomepage"
          >
            Přejít na hlavní stránku
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="showLoginModal"
      max-width="320"
      @input="hideLoginModal"
    >
      <v-card>
        <v-card-title class="headline">
          Přihlášení
        </v-card-title>
        <v-card-text>
          <login-form @success="hideLoginModal" />
          <v-flex
            xs12
            align-end
            flexbox
            text-xs-right
          >
            <v-btn
              to="/registrace"
              class="jh-m3--text"
              flat
              @click="hideLoginModal"
            >
              Registrovat se
            </v-btn>
          </v-flex>
          <v-flex
            xs12
            align-end
            flexbox
            text-xs-right
          >
            <v-btn
              to="/zapomenute-heslo"
              class="jh-m3--text"
              flat
              @click="hideLoginModal"
            >
              Zapoměl jsem heslo
            </v-btn>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
    <terms-dialog ref="terms" title="Pro pokračování je třeba souhlasit s aktuálními podmínkami" :allow-cancel="false" allow-decline
                  show-changes @accept="acceptTerms" @decline="declineTerms"
    ></terms-dialog>
  </v-app>
</template>

<script>
  import Toolbar from '~/components/layout/Toolbar'
  import { MUTATIONS, STATE } from '~/store/index'
  import { NAMESPACE as AUTH_NAMESPACE, STATE as AUTH_STATE } from '~/store/auth'
  import StyleMixin from '~/components/styleMixin'
  import LoginForm from '~/components/User/LoginForm'
  import TermsDialog from '~/components/User/TermsDialog'

  export default {
    components: { Toolbar, LoginForm, TermsDialog },
    mixins: [StyleMixin],
    props: {
      isHomepage: Boolean
    },
    data() {
      return {
        title: 'Jobhub',
        version: process.env.VERSION,
        M3LINK: process.env.M3LINK
      }
    },
    computed: {
      showLoginExpirationModal() {
        return !this.$store.state[AUTH_NAMESPACE][
          AUTH_STATE.IS_TOKEN_NOT_EXPIRED
          ]
      },
      footerBackgroundColorStyle() {
        return {
          'backgroundColor': this.$store.state[STATE.footerBackground],
        }
      },
      showLoginModal() {
        return this.$store.state[STATE.isLoginModalOpen]
      },
      currentYear() {
        return new Date().getFullYear()
      },
      isCustomDrawerOpen() {
        return this.$store.state[STATE.isCustomDrawerOpen]
      },
      logoSubTextConfig() {
        return this.$store.state[STATE.LOGO]
      },
      themeClass() {
        if (null === this.logoSubTextConfig) {
          return ''
        }
        return `jh-theme--${this.logoSubTextConfig.mode}`
      }
    },
    watch: {
      '$auth.hasUserAgreedWithCurrentTerms': {
        immediate: true,
        handler: function (value) {
          if (value === false) {
            this.$nextTick(() => {
              this.$refs.terms.open()
            })
          }
        }
      }
    },
    methods: {
      async acceptTerms() {
        try {
          await this.$axios.$put('/jh-api/me/terms/confirm-current', {})
          this.$refs.terms.close()
          this.$auth.refreshUser()
        } catch (e) {
          // skip catch
        } finally {
          this.$refs.terms.cancelTermsConfirmCancelPending()
        }
      },
      async declineTerms() {
        await this.$auth.logout()
        this.$refs.terms.close()
      },
      onLoginExpirationModal(state) {
        if (state === false && this.showLoginExpirationModal === true) {
          this.logoutAndGoToHomepage()
        }
      },
      closeCustomDrawer() {
        this.$store.commit(MUTATIONS.SET_CUSTOM_DRAWER, false)
      },
      openLoginModal() {
        this.$store.commit(MUTATIONS.SET_LOGIN_MODAL, true)
      },
      hideLoginModal() {
        this.$store.commit(MUTATIONS.SET_LOGIN_MODAL, false)
      },
      redirectToM3(path) {
        location.href = `${process.env.M3LINK}${path}`
      },
      async logoutAndGoToHomepage() {
        await this.$auth.logout()
        if (!this.$auth.isAllowedToAccessRoute(this.$route)) {
          this.$router.push('/')
        }
      },
      getScrollTop() {
        return window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop
      }
    }
  }
</script>
