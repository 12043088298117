export const JOBHUB = {
  jhDark: '#122335',
  jhM4: '#115280',
  jhM3: '#27b6b1',
  jhM2: '#E7336F',
  jhM1: '#EF7B19',
  jhM5: '#e6c32e',
  jhGrey: '#666464',
  jhBg: '#F0F3F8',
  jhWhite: '#ffffff',
}
