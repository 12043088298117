<template>
  <v-navigation-drawer
    :mini-variant="false"
    :clipped="true"
    :value="isOpen"
    fixed
    app
  >
    <v-list>
      <v-list-tile
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
        router
        exact
      >
        <v-list-tile-action> <v-icon>{{ item.icon }}</v-icon> </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title v-text="item.title" />
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { ITEMS } from './AdminMenuItems'
import { STATE } from '~/store'
export default {
  data() {
    return {
      drawer: false,
      items: ITEMS
    }
  },
  computed: {
    isOpen() {
      return this.$store.state[STATE.isAdminDrawerOpen]
    }
  }
}
</script>
