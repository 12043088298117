<template>
  <svg
    :class="_logoClass"
    clip-rule="evenodd"
    fill-rule="evenodd"
    image-rendering="optimizeQuality"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    version="1.1"
    viewBox="0 0 22000 6300"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- prettier-ignore -->
    <text
      xml:space="preserve"
      class="fil-text logo-text"
      text-anchor="start"
      y="3700"
      x="6700"
      stroke-width="0"
      stroke="#000"
      fill="white"
    >JOBHUB</text>
    <path
      :class="{ fil: isLoading }"
      class="fil-m4"
      d="m33 2641c718-884 1430-1760 2146-2641 107 180 198 364 280 552 146 334 266 676 312 1039 26 206 29 413-33 614-115 381-398 565-768 640-378 76-757 41-1133-21-269-43-534-105-804-183z"
    />
    <path
      :class="{ fil: isLoading }"
      class="fil-m3"
      d="m1662 6062c-555-851-1107-1696-1662-2547 99-38 200-47 298-62 460-68 919-101 1376 20 123 33 240 81 346 153 316 215 386 526 338 879-50 369-204 701-376 1025-96 179-200 354-320 532z"
    />
    <path
      :class="{ fil: isLoading }"
      class="fil-m2"
      d="m5195 5135c-886 373-1763 741-2648 1113-6-228-4-447 19-666 37-349 98-692 275-1003 192-338 473-474 848-409 128 21 250 65 367 121 391 188 722 461 1040 751 31 27 60 56 99 93z"
    />
    <path
      :class="{ fil: isLoading }"
      class="fil-m1"
      d="m5385 4571c-386-265-761-527-1015-919-145-223-209-462-85-718 71-148 186-258 318-352 303-215 647-335 1003-429 71-19 143-34 220-52-147 824-293 1640-441 2470z"
    />
    <path
      :class="{ fil: isLoading }"
      class="fil-m5"
      d="m3420 429c-201 795-201 2535 1786 1186-125-80-1786-1186-1786-1186z"
    />
    <!-- prettier-ignore -->
    <text v-if="logoSubTextConfig && !keepDefault" :fill="`#${logoSubTextConfig.color}`" :style="{ letterSpacing: `${logoSubTextConfig.spacing}px` }" xml:space="preserve"
          class="subText" text-anchor="start" y="5800" x="6700" stroke-width="0" stroke="#000"
    >{{ logoSubTextConfig.text }}</text>
  </svg>
</template>
<script>
  import { STATE } from '~/store/index'

  export default {
    props: {
      keepDefault: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      isLoading() {
        return this.$store.state[STATE.isLoading]
      },
      logoSubTextConfig() {
        return this.$store.state[STATE.LOGO]
      },
      _logoClass() {
        if (null === this.logoSubTextConfig || this.keepDefault === true) {
          return ''
        }
        return this.logoSubTextConfig.mode
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @require "../assets/style/colors.styl"

  .fil-text {
    fill: #fcffff;
    fill-rule: nonzero;
    font-family: 'Titillium Web', sans-serif;
    font-size: 3900px;
    font-weight: 700;
    letter-spacing: 110px;
    text-transform: uppercase;
  }

  .m1 {
    .fil-m2:not(.fil), .fil-m3:not(.fil), .fil-m4:not(.fil), .fil-m5:not(.fil) {
      fill: $jh-grey;
    }
  }

  .m2 {
    .fil-m1:not(.fil), .fil-m3:not(.fil), .fil-m4:not(.fil), .fil-m5:not(.fil) {
      fill: $jh-grey;
    }
  }

  .m3 {
    .fil-m2:not(.fil), .fil-m1:not(.fil), .fil-m4:not(.fil), .fil-m5:not(.fil) {
      fill: $jh-grey;
    }
  }

  .m4 {
    .fil-m2:not(.fil), .fil-m3:not(.fil), .fil-m1:not(.fil), .fil-m5:not(.fil) {
      fill: $jh-grey;
    }
  }

  .m5 {
    .fil-m2:not(.fil), .fil-m3:not(.fil), .fil-m4:not(.fil), .fil-m1:not(.fil) {
      fill: $jh-grey;
    }
  }

  .fil {
    animation: loading 2.5s infinite;
    transition: all 200ms;
    animation-fill-mode: forwards;
  }

  .fil-m1 {
    animation-delay: 0s;
    fill: $jh-m1;
    fill-rule: nonzero;
  }

  .fil-m2 {
    animation-delay: 0.5s;

    fill: $jh-m2;
    fill-rule: nonzero
  }

  .fil-m4 {
    animation-delay: 1s;
    fill: $jh-m4;
    fill-rule: nonzero
  }

  .fil-m3 {
    animation-delay: 1.5s;
    fill: $jh-m3;
    fill-rule: nonzero
  }

  .fil-m5 {
    animation-delay: 2s;
    fill: $jh-m5;
    fill-rule: nonzero
  }

  .subText {
    font-family: 'Titillium Web', sans-serif;
    font-size: 1100px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -80px;
  }

  @keyframes loading {
    0% {
      fill: $jh-m3;
    }
    25% {
      fill: $jh-m4;
    }
    50% {
      fill: $jh-m2;
    }
    75% {
      fill: $jh-m1;
    }
    100% {
      fill: $jh-m5;
    }
  }
</style>
