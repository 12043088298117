import Vue from 'vue'
import LogoLoading from './logoLoading'

const LoadingCmp = {
  data: {
    visible: false
  },
  render(h) {
    if (!this.visible) {
      return null
    }
    return h(
      'div',
      {
        staticClass: 'v-overlay v-overlay--active',
        attrs: {
          style: 'z-index: 10001;'
        }
      },
      [h(LogoLoading)]
    )
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}

export default class Loading {
  constructor() {
    this.component = undefined
  }

  getComponent() {
    if (!this.component) {
      const cmp = new Vue(LoadingCmp)
      window.document.body.appendChild(cmp.$mount().$el)
      this.component = cmp
    }
    return this.component
  }

  show() {
    if (!process.browser) {
      return
    }
    this.getComponent().show()
  }

  close() {
    if (!process.browser) {
      return
    }
    this.getComponent().close()
  }
}
