import { JOBHUB as JOBHUB_COLOR } from '~/utils/Colors'

export const MUTATIONS = {
  SET_USER_MENU: 'SET_USER_MENU',
  SET_ADMIN_DRAWER: 'SET_ADMIN_DRAWER',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  SET_LOADING: 'SET_LOADING',
  UNSET_LOADING: 'UNSET_LOADING',
  SET_LOGO: 'SET_LOGO',
  SET_CURRENT_VERSION: 'SET_CURRENT_VERSION',
  SET_LOGIN_MODAL: 'SET_LOGIN_MODAL',
  SET_FOOTER_COLOR: 'SET_FOOTER_COLOR',
  SET_CUSTOM_DRAWER: 'SET_CUSTOM_DRAWER'
}

export const STATE = {
  currentVersion: 'currentVersion',
  isUserMenuOpen: 'isUserMenuOpen',
  isCustomDrawerOpen: 'isCustomDrawerOpen',
  isLoginModalOpen: 'isLoginModalOpen',
  isAdminDrawerOpen: 'isAdminDrawerOpen',
  sidebar: 'sidebar',
  isLoading: 'isLoading',
  footerBackground: 'footerBackground',
  LOGO: 'LOGO'
}

export const LOGO = {
  DEFAULT: {
    color: 'fff',
    text: '',
    spacing: 0,
    mode: null
  },
  M1: {
    color: JOBHUB_COLOR.jhM1,
    text: 'Sebeevaluační nástroj',
    spacing: 0,
    mode: 'm1'
  },
  M2: {
    color: JOBHUB_COLOR.jhM2,
    text: 'Průvodce životními situacemi',
    spacing: -80,
    mode: 'm2'
  },
  M3: {
    color: JOBHUB_COLOR.jhM3,
    text: 'Katalog kariérových poradců',
    spacing: -80,
    mode: 'm3'
  },
  M4: {
    color: JOBHUB_COLOR.jhM4,
    text: 'Informace o trhu práce',
    spacing: 0,
    mode: 'm4'
  },
  M5: {
    color: JOBHUB_COLOR.jhM5,
    text: 'Informace pro úřady práce',
    spacing: -40,
    mode: 'm5'
  }
}

export const state = () => ({
  [STATE.sidebar]: false,
  [STATE.currentVersion]: 0,
  [STATE.isAdminDrawerOpen]: false,
  [STATE.isLoginModalOpen]: false,
  [STATE.isCustomDrawerOpen]: false,
  [STATE.isUserMenuOpen]: false,
  [STATE.isLoading]: false,
  [STATE.LOGO]: null,
  [STATE.footerBackground]: JOBHUB_COLOR.jhBg,
})

export const mutations = {
  [MUTATIONS.TOGGLE_SIDEBAR](state) {
    state[STATE.sidebar] = !state.sidebar
  },
  [MUTATIONS.SET_USER_MENU](state, isOpen) {
    state[STATE.isUserMenuOpen] = isOpen
  },
  [MUTATIONS.SET_ADMIN_DRAWER](state, isOpen) {
    state[STATE.isAdminDrawerOpen] = isOpen
  },
  [MUTATIONS.SET_CUSTOM_DRAWER](state, isOpen) {
    state[STATE.isCustomDrawerOpen] = isOpen
  },
  [MUTATIONS.SET_LOADING](state) {
    state[STATE.isLoading] = true
  },
  [MUTATIONS.UNSET_LOADING](state) {
    state[STATE.isLoading] = false
  },
  [MUTATIONS.SET_LOGO](state, logoConfig) {
    state[STATE.LOGO] = logoConfig
  },
  [MUTATIONS.SET_CURRENT_VERSION](state, version) {
    state[STATE.currentVersion] = version
  },
  [MUTATIONS.SET_LOGIN_MODAL](state, isOpen) {
    state[STATE.isLoginModalOpen] = isOpen
  },
  [MUTATIONS.SET_FOOTER_COLOR](state, color) {
    if (color === undefined) {
      state[STATE.footerBackground] = JOBHUB_COLOR.jhBg
      return
    }
    state[STATE.footerBackground] = color
  }
}

export const actions = {}
