import Vue from 'vue'
import {
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VBtn,
  VSpacer
} from 'vuetify/lib'
import _merge from 'lodash/merge'

const DEFAULT_OPTIONS = {
  title: '',
  text: '',
  buttons: [],
  dialogProps: {
    'max-width': 320,
    persistent: true,
    value: false,
  }
}

const DEFAULT_BUTTON_OPTIONS = {
  attrs: {
    flat: true,
    color: 'jhM3',
  }
}

const DialogCmp = {
  render(h) {
    return h(
      VDialog,
      {
        props: this.dialogProps,
      },
      [
        h(VCard, { attrs: {  } }, [
          h(VCardTitle, { staticClass: 'headline', }, this.title),
          this.text !== '' ? h(VCardText, {} , this.text) : null,
          this.renderButtons(h),
        ])
      ]
    )
  },
  data() {
    return Object.assign({},DEFAULT_OPTIONS)
  },

  methods: {
    renderButtons(h) {
      if (this.buttons.length === 0) {
        return null
      }
      let buttons = []
      for (let button of this.buttons) {
        if (button.action) {
          if (button.on === undefined) {
            button.on = {}
          }
          button.on.click = (e) => {
            button.action(e, this)
          }
        }
        buttons.push(h(VBtn, _merge({}, DEFAULT_BUTTON_OPTIONS, button), [button.title]))
      }

      return h(VCardActions, {}, [
        h(VSpacer, {}, []),
        ...buttons
      ])
    },
    show(options = {}) {
      if (this.dialogProps.value) {
        this.hide()
        this.$nextTick(() => this.show(options))
        return
      }

      Object.keys(options).forEach(field => {
        this.$set(this, field, options[field])
      })

      this.dialogProps.value = true
    },

    hide() {
      this.dialogProps.value = false
    },
  }
}

export default class Dialog
{
  constructor() {
    this.component = undefined
  }

  getComponent() {
    if (!this.component) {
      const cmp = new Vue(DialogCmp)
      window.document.body.appendChild(cmp.$mount().$el)
      this.component = cmp
    }
    return this.component
  }

  show(options) {
    if (!process.browser) {
      return
    }
    this.getComponent().show(_merge({}, DEFAULT_OPTIONS, options))
  }

  hide() {
    if (!process.browser) {
      return
    }
    this.getComponent().hide()
  }
}
