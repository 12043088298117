<template>
  <layout is-homepage>
    <nuxt />
  </layout>
</template>

<script>
  import Layout from '~/components/layout/page-layout'
  import LayoutHeadMixin from '~/components/LayoutHeadMixin'

  export default {
    components: { Layout },
    mixins: [LayoutHeadMixin],
  }
</script>
