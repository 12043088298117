export const NAMESPACE = 'mbti'

// const RANDOM_LEVEL = () => {
//   return Math.floor(Math.random() * 5) + 1
// }

export const CONTEXT = {
  TEEN: 'TEEN',
  ADULT: 'ADULT'
}

export const ACTIONS = {
  LOAD_QUESTIONS: 'LOAD_QUESTIONS',
  LOAD_TEST: 'LOAD_TEST',
  UPDATE_ANSWER: 'UPDATE_ANSWER',
  EVALUATE: 'EVALUATE',
  SET_CODE: 'SET_CODE'
}

export const MUTATIONS = {
  SET_ANSWER: 'SET_ANSWER',
  RESET_CODE: 'RESET_CODE'
}

export const STATE = {
  QUESTIONS: 'QUESTIONS',
  ANSWERS: 'ANSWERS',
  CODE: 'CODE',
  ACTIVE_CONTEXT: 'ACTIVE_CONTEXT'
}

export const state = () => ({
  [STATE.QUESTIONS]: [],
  [STATE.ANSWERS]: [],
  [STATE.ACTIVE_CONTEXT]: null,
  [STATE.CODE]: {
    [CONTEXT.ADULT]: null,
    [CONTEXT.TEEN]: null
  }
})

export const mutations = {
  [MUTATIONS.SET_ANSWER](state, { level, questionId }) {
    for (let answer of state[STATE.ANSWERS]) {
      if (answer.question === questionId) {
        answer.level = level
        return
      }
    }
  },
  [MUTATIONS.RESET_CODE](state, context) {
    state[STATE.CODE][context] = null
  },
  _setAnswers(state, answers) {
    state[STATE.ANSWERS] = answers
  },
  _setCode(state, { code, context }) {
    state[STATE.CODE][context] = code
    state[STATE.ACTIVE_CONTEXT] = context
  },
  _setQuestions(state, questions) {
    state[STATE.QUESTIONS] = questions
  }
}

export const actions = {
  async [ACTIONS.SET_CODE]({ dispatch }, { code, context }) {
    await dispatch('_checkCode', { context, code })
    this.$toast.success('Kód byl úspěšně zadán, můžete pokračovat')
  },
  async _checkCode({ commit }, { code, context }) {
    try {
      await this.$axios.$get('/jh-api/mbti/code-check', {
        params: { code, context }
      })
      commit('_setCode', { code, context })
    } catch (e) {
      this.$toast.error('Zadán neplatný kód')
      throw e
    }
  },
  async [ACTIONS.LOAD_TEST]({ commit, state }, { code, context }) {
    let activeCode =
      code === undefined ? state[STATE.CODE][context] : code
    if (!this.$auth.isLoggedIn && activeCode === null) {
      // when no code cancel loading or user is not logged
      return
    }
    try {
      this.$jhloader.show()
      let response = await this.$axios.$get('/jh-api/mbti', {
        params: { code: activeCode, context }
      })
      let answers = []
      for (let answer of response) {
        answers.push({
          question: answer.question.id,
          level: answer.level
        })
      }
      commit('_setAnswers', answers)
    } catch (e) {
      this.$toast.error('Zadán neplatný kód')
      throw e
    } finally {
      this.$jhloader.close()
    }
  },
  async [ACTIONS.LOAD_QUESTIONS]({ state, commit }, { context }) {
    let questions = await this.$axios.$get('/jh-api/mbti/questions', { params: { context } })
    if (state[STATE.CODE][context] === null && !this.$auth.isLoggedIn) {
      // when no code set or user not logged answers
      let answers = []
      for (let question of questions) {
        answers.push({
          question: question.id,
          // level: RANDOM_LEVEL()
          level: -1
        })
      }
      commit('_setAnswers', answers)
    }
    commit('_setQuestions', questions)
  },
  async [ACTIONS.UPDATE_ANSWER](
    { dispatch, commit },
    { level, questionId, context }
  ) {
    commit(MUTATIONS.SET_ANSWER, { level, questionId })
    dispatch(ACTIONS.EVALUATE, context)
  },
  async [ACTIONS.EVALUATE]({ state, commit }, context) {
    try {
      let response = await this.$axios.$post(
        '/jh-api/mbti',
        {
          user_test_form: { answers: state[STATE.ANSWERS] }
        },
        { params: { code: state[STATE.CODE][context], context } }
      )
      commit('_setCode', { code: response.code, context })
      return response
    } catch (e) {
      console.error('Chyba při vyhodnocení', e)
    }
  }
}
