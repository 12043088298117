<template>
  <v-container grid-list-xl>
    <v-layout v-if="error.isGeneral === false" row wrap>
      <v-flex xs12 offset-md2 md8 align-middle>
        <v-card flat min-height="250">
          <v-card-title class="headline">
            {{ error.message }}
          </v-card-title>
          <v-card-actions>
            <v-btn flat to="/">
              Přejít na hlavní stránku
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap>
      <v-flex xs12 md3 align-middle>
        <h1 class="display-4 text-xs-center">
          Chyba {{ error.statusCode }}
        </h1>
      </v-flex>
      <v-flex xs12 md9 align-middle>
        <v-card flat min-height="250">
          <template v-if="error.statusCode === 404">
            <v-card-title class="headline">
              Požadovaná stránka neexistuje
            </v-card-title>
          </template>
          <template v-else-if="error.statusCode === 401">
            <v-card-title class="headline">
              Požadovaná stránka vyžaduje přihlášení
            </v-card-title>
            <v-card-text>
              Prosíme <a @click="openLoginForm">
                přihlašte se
              </a> nebo
              <nuxt-link to="/registrace">
                zaregistruje
              </nuxt-link>
              .
            </v-card-text>
          </template>
          <template v-else-if="error.statusCode === 403">
            <v-card-title class="headline">
              Sem nemáte přístup :(
            </v-card-title>
          </template>
          <v-card-title v-else-if="error.message" class="headline">
            {{ error.message }}
          </v-card-title>
          <v-card-title v-else>
            Neznámá chyba
          </v-card-title>
          <v-card-actions>
            <v-btn flat to="/">
              Přejít na hlavní stránku
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { LOGO, MUTATIONS } from '~/store'
  import LayoutHeadMixin from '~/components/LayoutHeadMixin'

  export default {
    mixins: [LayoutHeadMixin],
    // eslint-disable-next-line
    props: ['error'],
    fetch({ store }) {
      store.commit(MUTATIONS.SET_LOGO, LOGO.M1)
      store.commit(MUTATIONS.SET_FOOTER_COLOR)
    },
    methods: {
      openLoginForm() {
        return this.$store.commit(MUTATIONS.SET_LOGIN_MODAL, true)
      }
    }
  }
</script>
