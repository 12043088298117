import { JOBHUB as JOBHUB_COLOR } from '~/utils/Colors'

export default {
  data(){
    return {
      JOBHUB_COLOR
    }
  },
  computed: {
    darkSection() {
      return {
        color: 'white',
        backgroundColor: this.$vuetify.theme.jhDark
      }
    },
    baseJumbo(){
      let {
        top,
        bar
      } = this.$vuetify.application
      return {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
        paddingTop: `${top + bar}px`,
        marginTop: `${(top + bar) * -1}px`
      }
    },
    jumboStyle() {
      return Object.assign({},this.baseJumbo,{backgroundImage: 'url(/bg.jpg)',})
    },
    m5JumboStyle() {
      return Object.assign({},this.baseJumbo,{backgroundImage: 'url(/bg_m5.jpg)',})
    },
    m1JumboStyle() {
      return Object.assign({},this.baseJumbo,{backgroundImage: 'url(/bg_m1.jpg)',})
    },
    m2JumboStyle() {
      return Object.assign({},this.baseJumbo,{backgroundImage: 'url(/bg_m2.jpg)',})
    },
    m3JumboStyle() {
      return Object.assign({},this.baseJumbo,{backgroundImage: 'url(/bg_m3.jpg)',})
    },
    m4JumboStyle() {
      return Object.assign({},this.baseJumbo,{backgroundImage: 'url(/bg_m4.jpg)',})
    }
  }
}
