export const NAMESPACE = 'jobs'

export const ACTIONS = {
  LOAD_JOBS: 'LOAD_JOBS',
  LOAD_UNITS: 'LOAD_UNITS'
}

export const MUTATIONS = {
  CLEAR_JOBS: 'CLEAR_JOBS',
  ADD_JOBS: 'ADD_JOBS',
  TOGGLE_JOB: 'TOGGLE_JOB',
  TOGGLE_JOB_LOADING: 'TOGGLE_JOB_LOADING',
  ADD_UNITS: 'ADD_UNITS',
  SET_LOADING: 'SET_LOADING'
}

export const STATE = {
  JOBS: 'JOBS',
  LOADING: 'LOADING',
  TOTAL_JOBS: 'TOTAL_JOBS'
}

export const GETTERS = {
  JOB_BY_UID: 'jobByUid',
}

export const state = () => ({
  [STATE.JOBS]: [],
  [STATE.LOADING]: false,
  [STATE.TOTAL_JOBS]: 0
})

export const getters = {
  [GETTERS.JOB_BY_UID]: (state) => (jobUid) => {
    return state[STATE.JOBS].find(job => job.uid === jobUid)
  }
}

export const mutations = {
  [MUTATIONS.CLEAR_JOBS](state) {
    state[STATE.JOBS] = []
  },
  [MUTATIONS.ADD_JOBS](state, { jobs, clear, total }) {
    if (clear === true) {
      state[STATE.JOBS] = []
    }
    state[STATE.JOBS].push(...jobs)
    state[STATE.TOTAL_JOBS] = total
  },
  [MUTATIONS.SET_LOADING](state, isLoading) {
    state[STATE.LOADING] = isLoading
  },
  [MUTATIONS.TOGGLE_JOB](state, jobUid) {
    state[STATE.JOBS] = [
      ...state[STATE.JOBS].map(job => {
        if (job.uid === jobUid) {
          job.expanded = !job.expanded
        }
        return job
      })
    ]
  },
  [MUTATIONS.TOGGLE_JOB_LOADING](state, jobUid) {
    state[STATE.JOBS] = [
      ...state[STATE.JOBS].map(job => {
        if (job.uid === jobUid) {
          job.loading = !job.loading
        }
        return job
      })
    ]
  },
  [MUTATIONS.ADD_UNITS](state, { jobUid, units, clear, total }) {
    state[STATE.JOBS] = [
      ...state[STATE.JOBS].map(job => {
        if (job.uid === jobUid) {
          job.unitsTotal = total
          if (clear === true) {
            job.units = units
          } else {
            job.units.push(units)
          }
        }
        return job
      })
    ]
  }
}

export const actions = {
  async [ACTIONS.LOAD_UNITS]({ commit, getters }, { jobUid, clear = false }) {
    commit(MUTATIONS.TOGGLE_JOB, jobUid)
    commit(MUTATIONS.TOGGLE_JOB_LOADING, jobUid)
    let job = getters[GETTERS.JOB_BY_UID](jobUid)
    if (job.expanded === false) {
      return
    }
    try {
      const response = await this.$axios.get(`/jh-api/jobs/${jobUid}/units`)
      commit(MUTATIONS.ADD_UNITS, { jobUid: jobUid, units: response.data, clear, total: parseInt(response.headers['x-count']) })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.TOGGLE_JOB_LOADING, jobUid)
    }
  },
  async [ACTIONS.LOAD_JOBS]({ commit, state }, { query, clear = false }) {
    commit(MUTATIONS.SET_LOADING, true)
    if (clear === true) {
      commit(MUTATIONS.CLEAR_JOBS)
    }
    try {
      const response = await this.$axios.get('/jh-api/jobs', { params: Object.assign({}, query, { offset: state[STATE.JOBS].length }) })
      commit(MUTATIONS.ADD_JOBS, { jobs: response.data, clear, total: parseInt(response.headers['x-count']) })
    } catch (e) {
      throw e
    } finally {
      commit(MUTATIONS.SET_LOADING, false)
    }
  }
}
